import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrailBaseService } from 'src/app/@core/services/trail-base.service';
import { TrailStage } from 'src/app/@core/models/trail-stage';
import { TrailSubStage } from 'src/app/@core/models/trail-substage';

@Component({
  selector: 'app-sub-stage-dialog',
  templateUrl: 'sub-stage-dialog.component.html',
})
export class SubStageDialogComponent {
  substage: TrailSubStage;
  trailStageArray: Array<TrailStage>;

  isPosting: boolean;
  fileSelected: any;

  subStageForm: FormGroup;

  constructor(
    public trailService: TrailBaseService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SubStageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGenerate();

    this.trailStageArray = data.trailStageArray;

    if (this.trailStageArray.length === 1) {
      this.subStageForm.get('stage').setValue(this.trailStageArray[0].id);
    }

    if (data.substage) {
      this.substage = data.substage;

      this.subStageForm.patchValue({
        title: this.substage.title,
        stage: this.substage.stage,
      });
    }
  }

  formGenerate() {
    this.subStageForm = this.formBuilder.group({
      title: ['', Validators.required],
      stage: ['', Validators.required]
    });
  }

  onSubmit() {
    const form = this.subStageForm;
    console.log(form.value);

    if (!form.valid) {
      return;
    }

    this.isPosting = true;

    const formValue = form.value;

    if (!this.substage) {
      this.insertSubStage(formValue.stage, formValue.title);
    } else {
      this.updateSubStage(formValue.stage, formValue.title);
    }
  }

  insertSubStage(stageId: number, title: string) {
    this.trailService.insertSubStage(stageId, title)
      .subscribe((res: any) => {
        const data = res;

        const subStage = new TrailSubStage(data.id, data.nome);
        subStage.stage = data.etapa_id;

        this.closeDialog(subStage);
      });
  }

  updateSubStage(stageId: number, title: string) {
    this.trailService.updateSubStage(stageId, this.substage.id, title)
      .subscribe((res: any) => {
        const data = res.data;

        const subStage = new TrailSubStage(data.id, data.nome);
        subStage.stage = data.etapa_id;

        this.closeDialog(subStage);
      });
  }

  closeDialog(subsStage: TrailSubStage): void {
    this.isPosting = false;
    this.subStageForm.reset();
    this.dialogRef.close(subsStage);
  }

  getPhoto(image: { path: string }) {
    this.fileSelected = image.path;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
