import { Component } from '@angular/core';

@Component({
  selector: 'app-editor-component-sales-channel-config',
  templateUrl: 'sales-channel-config.component.html',
})

export class EditorSalesChannelConfigComponent {


    groups = [
      {
        name: 'Feiras Locais e Regionais',
        perguntas: [
          'Consegue fazer pesquisa das feiras existentes e calendário?',
          'Consegue fazer pesquisa prévia para entender o perfil do público que visita essas feiras?',
          'Consegue oferecer produtos que esse perfil de cliente gostaria de comprar?',
          'Tem capacidade para montar stand de vendas decorado e organizado?',
          'Dispõe de vendedora cativante e simpática?',
          'Tem recurso para pagar taxa de inscrição ou taxa pelo espaço?',
          'Possui máquina de cartão?',
          'Possui embalagem bonita que promova o produto?',
          'Possui material de divulgação básico como cartão de visita, etiqueta contando a história do produto e folder?'
        ],
        resposta: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ]

      },

      {
        name: 'Loja Itinerante (bike, carro ou expositor móvel)',
        perguntas: [
          'Tem ou sabe como faz para ter o Alvará de Funcionamento?',
          'Possui investimento para comprar, adaptar e decorar o veículo?',
          'Sabe onde pesquisar referências para a decoração da loja itinerante, bem como apresentação dos produtos dentro dela?',
          'Tem uma pessoa que seja motorista/vendedor que, ao mesmo tempo, pilote o veículo, saiba vender e atender bem aos clientes?',
          'Possui controles das vendas (caderno ou um sistema no computador) para saber o que vende, quando produzir e quando repor?',
          'Tem capacidade de repor os produtos na loja itinerante, com novidades toda semana?',
          'Consegue fazer pesquisa das feiras, eventos, locais de grande circulação existentes e calendário?',
          'Consegue fazer pesquisa prévia para entender o perfil do cliente, saber onde ele está e ir até o mesmo com produtos que ele gostaria de comprar?',
          'Possui máquina de cartão?'
        ],
        resposta: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ]
      },

      {
        name: 'Display',
        perguntas: [
          'Possui investimento para montar e decorar o display?',
          'Consegue fazer pesquisa dos principais eventos, com grande circulação, que têm a ver com os seus produtos?',
          'Consegue ter acesso aos estabelecimentos para negociar a locação do display?',
          'Consegue fazer pesquisa prévia para entender o perfil do cliente que frequenta os lugares de exposição?',
          'É capaz de se transportar e repor o estoque nos locais de exposição, constantemente?',
          'Consegue adaptar os produtos de acordo com as vendas?',
          'Possui peças etiquetadas com preços e história do grupo?',
          'Possui embalagens?',
          'É organizado para controlar as vendas de cada display?'
        ],
        resposta: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ]
      },

      { name: 'Sua loja dentro de outra loja', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Visitas de Experiência', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Venda Direta (sacoleiras ou catálogo)', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Prestação de Serviços (produtos exclusivos)', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Prestação de Serviços (aulas)', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Prestação de Serviços (festas e eventos)', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Prestação de Serviços (cenografia)', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Vendas pela Internet ou Shopping Online', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Loja Própria', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Venda para Lojas', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] },

      { name: 'Venda para Navios Turísticos', perguntas: ['Pergunta 1', 'Pergunta 2', 'Pergunta 3', 'Pergunta 4', 'Pergunta 5', 'Pergunta 6', 'Pergunta 7', 'Pergunta 8', 'Pergunta 9'] }
    ];


}
