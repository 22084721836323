import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { StorageService } from 'src/app/@core/services/storage.service';
import { ClientService } from 'src/app/@core/services/client.service';
import { TrailService } from 'src/app/@core/services/trail.service';
import { TopMenuDialogComponent } from './dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { ModalNotificationEnableComponent } from './modal-notification-enable/modal-notification-enable.component';

declare var $: any;

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})

export class TopMenuComponent implements OnInit {
  @Input() trail: any;
  @Output() public trailUpdated = new EventEmitter<any>();

  public id: number;
  routerLevel: string;
  trailConfig: any;

  trailItemForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public clientService: ClientService,
    public trailService: TrailService,
    public storage: StorageService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((param: Params) => {
      this.id = +param.id;

      this.routerLevel = this.getRouterLevel();

      this.checkRules();
    });

  }

  getRouterLevel(): string {
    const routerConfigPath = this.activatedRoute.snapshot.routeConfig.path;
    if (['nivel', 'mini', 'develop', 'article'].indexOf(routerConfigPath) > -1) {
      return this.activatedRoute.snapshot.routeConfig.path;
    }
  }

  checkRules() {
    const lu = this.storage.get('loggedUser');
    if (lu) {
      this.clientService.getColor().subscribe((response: any) => {
        if (response[0].config.trail) {
          this.trailConfig = response[0].config.trail;
        } else {
          this.trailConfig = {
            payment: true,
            classes: true,
            config: true,
            gamification: true,
            allowance: true,
            cleaning: true,
            support: true
          };
        }
      });
    }
  }

  statusClass() {
    this.trail.class.enables = !this.trail.class.enables;

    this.trailService.setClass(this.id)
      .subscribe((res: any) => {
        console.log(res);
      }, (err: any) => {
        console.log('err', err);
        this.trail.class.enables = !this.trail.class.enables;
      });
  }

  statusToggle() {
    if (this.trail.status == 0) {
      const dialogNotification = this.dialog.open(ModalNotificationEnableComponent, {
        width: '400px',
        data: this.trail
      })

      dialogNotification.afterClosed().subscribe(result => {

        this.trail.status = (this.trail.status === 1) ? 0 : 1;
        this.trailService.setStatus(this.trail.status, this.id)
          .subscribe((res) => { }, err => {
            this.trail.status = (this.trail.status === 1) ? 0 : 1;
          });
      });
    }
    else {
      this.trail.status = (this.trail.status === 1) ? 0 : 1;
      this.trailService.setStatus(this.trail.status, this.id)
        .subscribe((res) => { }, err => {
          this.trail.status = (this.trail.status === 1) ? 0 : 1;
        });
    }

  }

  updateTrail() {
    const dialogRef = this.dialog.open(TopMenuDialogComponent, {
      data: {
        trail: this.trail,
        config: this.trailConfig
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.trailUpdated.emit(true);
      }
    });
  }

  closeWindow() {
    console.log('closeWindow');
    window.close();
  }


}
