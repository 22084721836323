import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { VideoItemComponent } from 'src/app/@core/models/item-component';
import { MatDialog } from '@angular/material/dialog';
import { GalleryDialogComponent } from '../gallery-dialog/gallery-dialog.component';

@Component({
  selector: 'app-editor-component-video-config',
  templateUrl: 'video-config.component.html'
})
export class EditorVideoConfigComponent {
  @Input() item: VideoItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  showVideo = true;

  constructor(
    public dialog: MatDialog
  ) {
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  openGallery(): void {
    const dialogRef = this.dialog.open(GalleryDialogComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed: result', result);
      this.item.source_path = result.url;
      if (result.url.includes('cloudinary')) {
        this.item.poster = result.url.split('.mp4').join('.webp');
      }
    });
  }

  videoChanged() {
    this.showVideo = false;
    setTimeout(() => {
      this.showVideo = true;
    }, 500);
  }
}
