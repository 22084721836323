import { EditorAvaliationComponent } from './avaliation/avaliation.component';
import { EditorAvaliationConfigComponent } from './avaliation/config/avaliation-config.component';
import { EditorChallengeComponent } from './challenge/challenge.component';
import { EditorChallengeConfigComponent } from './challenge/config/challenge-config.component';
import { EditorConclusionComponent } from './conclusion/conclusion.component';
import { EditorConclusionConfigComponent } from './conclusion/config/conclusion-config.component';
import { EditorContractComponent } from './contract/contract.component';
import { EditorContractConfigComponent } from './contract/config/contract-config.component';
import { EditorDownloadComponent } from './download/download.component';
import { EditorDownloadConfigComponent } from './download/config/download-config.component';
import { EditorDynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { EditorDynamicTableConfigComponent } from './dynamic-table/config/dynamic-table-config.component';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component';
import { EditorFormStepComponent } from './form-step/form-step.component';
import { EditorFormStepConfigComponent } from './form-step/config/form-step-config.component';
import { ForumComponent } from './forum/forum.component';
import { EditorForumConfigComponent } from './forum/config/forum-config.component';
import { EditorImageComponent } from './image/image.component';
import { EditorImageConfigComponent } from './image/config/image-config.component';
import { EditorIntroComponent } from './intro/intro.component';
import { EditorIntroConfigComponent } from './intro/config/intro-config.component';
import { EditorLongTextComponent } from './long-text/long-text.component';
import { EditorLongTextConfigComponent } from './long-text/config/long-text-config.component';
import { EditorMultiChoiceComponent } from './multichoice/multichoice.component';
import { EditorMultichoiceConfigComponent } from './multichoice/config/multichoice-config.component';
import { EditorMultipleQuestionsComponent } from './multiple-questions/multiple-questions.component';
import { EditorMultipleQuestionsConfigComponent } from './multiple-questions/config/multiple-questions-config.component';
import { EditorPdfComponent } from './pdf/pdf.component';
import { EditorPdfConfigComponent } from './pdf/config/pdf-config.component';
import { EditorRatingComponent } from './rating/rating.component';
import { EditorRatingConfigComponent } from './rating/config/rating-config.component';
import { EditorSalesChannelComponent } from './sales-channel/sales-channel.component';
import { EditorSalesChannelConfigComponent } from './sales-channel/config/sales-channel-config.component';
import { EditorSheetCorrectIncorrectComponent } from './sheet-correct-incorrect/sheet-correct-incorrect.component';
import { EditorSheetCorrectIncorrectConfigComponent } from './sheet-correct-incorrect/config/sheet-correct-incorrect-config.component';
import { EditorStarPointComponent } from './star-point/star-point.component';
import { EditorStarPointConfigComponent } from './star-point/config/star-point-config.component';
import { EditorTextComponent } from './text/text.component';
import { EditorTextConfigComponent } from './text/config/text-config.component';
import { EditorUploadComponent } from './upload/upload.component';
import { EditorUploadConfigComponent } from './upload/config/upload-config.component';
import { EditorVideoComponent } from './video/video.component';
import { EditorVideoConfigComponent } from './video/config/video-config.component';
import { EditorYesNoComponent } from './yes-no/yes-no.component';
import { EditorYesNoConfigComponent } from './yes-no/config/yes-no-config.component';
import { GalleryDialogComponent } from './video/gallery-dialog/gallery-dialog.component';
import { EditorExercisesComponent } from './exercises/exercises.component';
import { EditorExercisesConfigComponent } from './exercises/config/exercises-config.component';
import { EditorResearchComponent } from './research/research.component';
import { EditorResearchConfigComponent } from './research/config/research-config.component';
import { EditorFeedbackComponent } from './feedback/feedback.component';
import { EditorFeedbackConfigComponent } from './feedback/config/feedback-config.component';
import { EditorAudioComponent } from './audio/audio.component';
import { EditorAudioConfigComponent } from './audio/config/audio-config.component';
import { EditorRandomQuestionComponent } from './random-questions/random-question.component';
import { EditorRandomQuestionConfigComponent } from './random-questions/config/random-question-config.component';
import { EditorPageComponent } from './page/page.component';
import { EditorPageConfigComponent } from './page/config/page-config.component';

export const EDITOR_COMPONENTS = [
  EditorAvaliationComponent,
  EditorAvaliationConfigComponent,
  EditorChallengeComponent,
  EditorChallengeConfigComponent,
  EditorConclusionComponent,
  EditorConclusionConfigComponent,
  EditorContractComponent,
  EditorContractConfigComponent,
  EditorDownloadComponent,
  EditorDownloadConfigComponent,
  EditorDynamicTableComponent,
  EditorDynamicTableConfigComponent,
  DynamicInputComponent,
  EditorFormStepComponent,
  ForumComponent,
  EditorFormStepConfigComponent,
  EditorForumConfigComponent,
  EditorImageComponent,
  EditorImageConfigComponent,
  EditorIntroComponent,
  EditorIntroConfigComponent,
  EditorLongTextComponent,
  EditorLongTextConfigComponent,
  EditorMultiChoiceComponent,
  EditorMultichoiceConfigComponent,
  EditorMultipleQuestionsComponent,
  EditorMultipleQuestionsConfigComponent,
  EditorPdfComponent,
  EditorPdfConfigComponent,
  EditorRatingComponent,
  EditorRatingConfigComponent,
  EditorSalesChannelComponent,
  EditorSalesChannelConfigComponent,
  EditorSheetCorrectIncorrectComponent,
  EditorSheetCorrectIncorrectConfigComponent,
  EditorStarPointComponent,
  EditorStarPointConfigComponent,
  EditorTextComponent,
  EditorTextConfigComponent,
  EditorUploadComponent,
  EditorUploadConfigComponent,
  EditorVideoComponent,
  EditorVideoConfigComponent,
  EditorYesNoComponent,
  EditorYesNoConfigComponent,
  GalleryDialogComponent,
  EditorExercisesComponent,
  EditorExercisesConfigComponent,
  EditorResearchComponent,
  EditorResearchConfigComponent,
  EditorFeedbackComponent,
  EditorFeedbackConfigComponent,
  EditorAudioComponent,
  EditorAudioConfigComponent,
  EditorRandomQuestionComponent,
  EditorRandomQuestionConfigComponent,
  EditorPageComponent,
  EditorPageConfigComponent
];
