import { Component, Input } from '@angular/core';
import { RandomQuestionsItemComponent } from 'src/app/@core/models/item-component';
import { TranslateSharedModule } from 'src/app/translate/translate.module';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor-component-random-questions',
  templateUrl: 'random-question.component.html'
})
export class EditorRandomQuestionComponent {
  @Input() item: RandomQuestionsItemComponent;

  blocked = false;
  listMarkerLetter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];

  constructor(private translateService: TranslateSharedModule) {
  }

  onSubmit() {
  }

  getArray(length: number): Array<any> {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(i);
    }
    return arr;
  }

  async verify(question: {correct: string, feedback: string}) {
    if (question.correct) {
        Swal.fire(await this.translateService.showTranslator('YouGot'), question.feedback, 'success');
    } else {
        Swal.fire('Ops...', question.feedback, 'error');
    }
  }

}
