import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GalleryService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http, 'api/v2/client', 'gallery')
  }

}