import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-certificado-config',
  templateUrl: 'certificado-config.component.html'
})

export class CertificadoConfigComponent {
  item: any;
  @Input() certificate: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  ngOnInit(): void {
    console.log('Meu certificado' , this.certificate)
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  logoSubmited($event) {
    this.certificate.logo = $event.path;
  }

  footerSubmited($event) {
    this.certificate.footer = $event.path;
  }

  assignSubmited($event) {
    this.certificate.assign = $event.path;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
