import {HttpHeaders} from '@angular/common/http';

export class LibraryHelper {

  static getHeaders(): any {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

  static removeFromArrayById(data: any, id: number): any {
    return data.filter(item => item.id !== id);
  }

  /**
   * Verifica se existe algum dado no array com o needle passado
   * @param needle
   * @param haystack
   * @returns {boolean}
   */
  static inArray(needle, haystack) {
    const result = this.arraySearch(needle, haystack);
    if (result !== false) {
      return true;
    }
    return result;
  }

  /**
   * Busca um array pelo key
   * @param needle
   * @param haystack
   * @returns {any}
   */
  static arraySearch(needle, haystack) {
    for (const i in haystack) {
      if (haystack[i] === needle) {
        return i;
      }
    }
    return false;
  }

  /**
   * Função semelhante ao do PHP
   * @param inputArray
   * @param columnKey
   * @param indexKey
   * @returns {any[] | {}}
   */
  static arrayColumn(inputArray, columnKey, indexKey = null) {
    function isArray(inputValue) {
      return Object.prototype.toString.call(inputValue) === '[object Array]';
    }

    // If input array is an object instead of an array,
    // convert it to an array.
    if (!isArray(inputArray)) {
      const newArray = [];
      for (const key in inputArray) {
        if (!inputArray.hasOwnProperty(key)) {
          continue;
        }
        newArray.push(inputArray[key]);
      }
      inputArray = newArray;
    }

    // Process the input array.
    const isReturnArray = (typeof indexKey === 'undefined' || indexKey === null);
    const outputArray = [];
    const outputObject = {};

    for (let inputIndex = 0; inputIndex < inputArray.length; inputIndex++) {
      const inputElement = inputArray[inputIndex];

      let outputElement;
      if (columnKey === null) {
        outputElement = inputElement;
      } else {
        if (isArray(inputElement)) {
          if (columnKey < 0 || columnKey >= inputElement.length) {
            continue;
          }
        } else {
          if (!inputElement.hasOwnProperty(columnKey)) {
            continue;
          }
        }

        outputElement = inputElement[columnKey];
      }

      if (isReturnArray) {
        outputArray.push(outputElement);
      } else {
        outputObject[inputElement[indexKey]] = outputElement;
      }
    }

    return (isReturnArray ? outputArray : outputObject);
  }

  static getClient(clientId: number) {
    const arrClient = [
      {
        id: 3,
        name: 'Asta',
        url: '/asta/login',
        urlLogout: '/auth'
      }, {
        id: 6,
        name: 'Fitness Link',
        url: '/fitness-link/login',
        urlLogout: '/auth'
      }, {
        id: 8,
        name: 'Smart Fit',
        url: '/smartfit/login',
        urlLogout: '/auth'
      }, {
        id: 9,
        name: 'Action Aid',
        url: '/action-aid/login',
        urlLogout: '/auth'
      }, {
        id: 15,
        name: 'Lele',
        url: '/lele/login',
        urlLogout: '/auth'
      },{
        id: 16,
        name: 'Admin',
        url: '/auth/login',
        urlLogout: '/auth'
      },{
        id: 18,
        name: 'Nova Terra',
        url: '/nova-terra/login',
        urlLogout: '/auth'
      }
    ];
    return arrClient.filter(item => item.id === clientId);
  }

}
