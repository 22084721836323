import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { TrailBaseService } from 'src/app/@core/services/trail-base.service';
import { TrailStage } from 'src/app/@core/models/trail-stage';

@Component({
  selector: 'app-featured-dialog',
  templateUrl: 'featured-dialog.component.html',
})
export class FeaturedDialogComponent {
  id: number;
  stage: TrailStage;
  isPosting: boolean;

  constructor(
    public trailService: TrailBaseService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FeaturedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.stage) {
      this.id = data.id;
      this.stage = data.stage;
    }
  }

  onSubmit() {
    this.isPosting = true;
    this.trailService.stagePut(this.id, this.stage)
      .subscribe(res => {
        console.log(res);
        this.closeDialog();
      });
  }

  closeDialog(): void {
    this.isPosting = false;
    this.dialogRef.close(this.stage);
  }

}
