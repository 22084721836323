import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateSharedModule } from 'src/app/translate/translate.module';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor-component-research-config',
  templateUrl: 'research-config.component.html',
  providers: []
})
export class EditorResearchConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();


  constructor(private translateService: TranslateSharedModule
  ) {
  }

  ngOnInit(): void {
    if (!this.item.questions || this.item.questions.length == 0) {
      this.item.questions = [];
      this.createNew();
    }
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  async createNew() {
    this.item.questions.push({
      question: await this.translateService.showTranslator('Question'),
      type: 'objetiva',
      options: [],
      lines: 1,
      collumns: []
    });
  }

  async insertOption(questionIndex: number) {
    this.item.questions[questionIndex].options.push({
      text: await this.translateService.showTranslator('Option')
    });
  }

  async insertCollumn(questionIndex: number) {
    this.item.questions[questionIndex].collumns.push({
      text: await this.translateService.showTranslator('Column'),
    });
  }

  removeQuestion(questionIndex: number) {
    this.item.questions.splice(questionIndex, 1);
  }

  removeCollumn(questionIndex: number, collumnIndex: number): void {
    this.item.questions[questionIndex].collumns.splice(collumnIndex, 1);
  }

  removeOption(questionIndex: number, option: number): void {
    this.item.questions[questionIndex].options.splice(option, 1);
  }

  onSelectionChange(question: any) {
    console.log('onSelectionChange: question', question);

  }

  async verify(question) {
    if (question.correct) {
        Swal.fire(await this.translateService.showTranslator('YouGot'), question.feedback, 'success');
    } else {
        Swal.fire('Ops...', question.feedback, 'error');
    }
  }

}
