import { Component, Input, OnInit } from '@angular/core';
import { PdfItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-pdf',
  templateUrl: 'pdf.component.html'
})
export class EditorPdfComponent implements OnInit {
  @Input() item: PdfItemComponent;

  ngOnInit() {
    if (this.item.url) {
      this.item.url = this.item.url.replace('/archives/', '/archives%2');
    }
  }

}
