import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TableComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-dynamic-table',
  templateUrl: 'dynamic-table.component.html',
})

export class EditorDynamicTableComponent implements OnInit {
  @Input() item: TableComponent;
  public view: Array<any>;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.view = [];
    this.form = this.createGroup();
  }

  ngOnInit() {
    this.createView();
  }

  createGroup() {
    const group = this.fb.group({});
    return group;
  }

  createView() {
    if (!this.item.userActive) {
      for (let i = 0; i < this.item.countLines; i++){
        this.view.push(Object.create(this.item.collumns));
      }
    } else {
      this.view.push(Object.create(this.item.collumns));
    }
  }

  calc() {
  }

  removeView(index: number) {
    this.view.splice(index, 1);
  }

}
