import { Component, Input } from '@angular/core';

import Swal from 'sweetalert2';
import { MultiChoiceComponent, MultiChoiceOptions } from 'src/app/@core/models/item-component';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

@Component({
  selector: 'app-editor-component-multichoice',
  templateUrl: 'multichoice.component.html',
})

export class EditorMultiChoiceComponent {
  @Input() item: MultiChoiceComponent;
  @Input() customData: any;

  constructor(private translateService: TranslateSharedModule){

  }

  listMarkerRoman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  listMarkerLetter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
  listMarkerNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  blocked = false;

  onSelectionChange(option: MultiChoiceOptions): void {
    for (const opt of this.item.options) {
      opt.selected = false;
    }
    option.selected = true;
  }

  async onSubmit() {

    const correctOption = this.item.options.find(item => {
      return item.correct === true;
    });

    const selectedOption = this.item.options.find(item => {
      return item.selected === true;
    });

    if (this.item.verification === 'single') {
      this.blocked = true;
    }

    if (correctOption.selected) {
      Swal.fire(
        (await this.translateService.showTranslator('Congratulations'))+'!',
        (await this.translateService.showTranslator('SelectedCorrectOption'))+'!',
        'success'
      )
    } else {
      if (this.item.feedback === 'GENERAL') {
        Swal.fire(
          (await this.translateService.showTranslator('IncorrectOption'))+'!',
          this.item.feedback_text,
          'error'
        );
      } else {
        Swal.fire(
          (await this.translateService.showTranslator('IncorrectOption'))+'!',
          selectedOption.messageInError,
          'error'
        );
      }
    }

  }
}
