import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-component-rating-config',
  templateUrl: 'rating-config.component.html',
})

export class EditorRatingConfigComponent {

  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor() { }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
