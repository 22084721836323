import { Component, Input, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-editor-component-research',
  templateUrl: 'research.component.html',
  styleUrls: ['./research.component.scss']
})

export class EditorResearchComponent {

  @Input() item: any;

  @ViewChild('slideCarousel', { static: false }) slideCarousel: any;

  blocked = false;
  // listMarker = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
  listMarker: Array<string> = 'abcdefghijklmnopqrstuvwxyz'.split('');

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 0,
    stagePadding: 0,
    // animateOut: 'slideOutUp',
    // animateIn: 'slideInUp',
    navSpeed: 1000,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>"
    ],
    items: 1,
    nav: true
  }

  constructor() {
  }

  onSubmit() {

  }

  public slide() {
    this.slideCarousel.next();
  }


  getArray(length: number): Array<any> {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(i);
    }
    return arr;
  }

}
