import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Trail } from '../models/trail';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibraryHelper } from '../helpers/library.helper';

@Injectable({ providedIn: 'root' })
export class TrailService {

  protected arrayModel: Trail[] = [];

  constructor(protected trailModel: Trail, private http: HttpClient) {
  }

  collection(type = null): Promise<any> {
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      this.findAll(type).subscribe(res => {
        for (const trail of res.data) {
          this.arrayModel.push(this.trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            role: trail.role,
            sinopse: trail.sinopse,
            date: trail.created_at.date,
            permission: trail.permission,
            tag: trail.tag,
          }));
        }
        resolve(this.arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  collectionNivel(): Promise<any> {
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      this.findNivelAll().subscribe(res => {
        for (const trail of res.data) {
          this.arrayModel.push(this.trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            sinopse: trail.sinopse,
            role: trail.role,
            date: trail.created_at.date,
            permission: trail.permission,
            tag: trail.tag,
          }));
        }
        resolve(this.arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  collectionMini(): Promise<any> {
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      this.findMiniAll().subscribe(res => {
        for (const trail of res.data) {
          this.arrayModel.push(this.trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            role: trail.role,
            date: trail.created_at.date,
            permission: trail.permission,
            tag: trail.tag,
          }));
        }
        resolve(this.arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  collectionArticle(): Promise<any> {
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      this.findArticleAll().subscribe(res => {
        for (const trail of res.data) {
          this.arrayModel.push(this.trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            role: trail.role,
            date: trail.created_at.date,
            permission: trail.permission,
            tag: trail.tag,
          }));
        }
        resolve(this.arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  collectionDevelop(): Promise<any> {
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      this.findDevelopAll().subscribe(res => {
        for (const trail of res.data) {
          this.arrayModel.push(this.trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            role: trail.role,
            date: trail.created_at.date,
            permission: trail.permission,
            tag: trail.tag,
          }));
        }
        resolve(this.arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  collectionGroup(): Promise<any> {
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      this.findGroupAll().subscribe(res => {
        for (const trail of res.data) {
          this.arrayModel.push(this.trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            role: trail.role,
            date: trail.created_at.date,
            permission: trail.permission,
            tag: trail.tag,
          }));
        }
        resolve(this.arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  public updateItem(
    id: number,
    trailId: number,
    title: string,
    description: string,
    image: string,
    nivel: string,
    selo: string,
    value: any,
    percentage_certificate: number = 0,
    time: number,
    course_required: number
  ): Observable<any> {
    const url = environment.baseUrl + '/api/v2/admin/trail/item/' + id + '/update';
    const body = {
      pasta_id: trailId,
      titulo: title,
      descricao: description,
      arquivo: image,
      nivel,
      selo,
      value,
      percentage_certificate,
      time,
      course_required
    };
    return this.http.put(url, body, LibraryHelper.getHeaders());
  }

  getPermission(): Observable<any> {
    return this.trailModel.getPermission();
  }

  findAll(type = null): Observable<any> {
    return this.trailModel.findAll(type);
  }

  findNivelAll(): Observable<any> {
    return this.trailModel.findNivelAll();
  }

  findMiniAll(): Observable<any> {
    return this.trailModel.findMiniAll();
  }

  findArticleAll(): Observable<any> {
    return this.trailModel.findArticleAll();
  }

  findDevelopAll(): Observable<any> {
    return this.trailModel.findDevelopAll();
  }

  findGroupAll(): Observable<any> {
    return this.trailModel.findGroupAll();
  }


  findAllClient(): Observable<any> {
    return this.trailModel.findAllClient();
  }

  findOneBy(data: any): Observable<any> {
    return undefined;
  }

  findBy(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.trailModel.findBy(id)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  filterBy(data: any[]): Observable<any> {
    return this.trailModel.filterBy(data);
  }

  persist(trail: Trail): Observable<any> {
    return this.trailModel.persist(trail);
  }

  persist_nivel(trail: Trail): Observable<any> {
    return this.trailModel.persist_nivel(trail);
  }

  persist_mini(trail: Trail): Observable<any> {
    return this.trailModel.persist_mini(trail);
  }

  persist_article(trail: Trail): Observable<any> {
    return this.trailModel.persist_article(trail);
  }

  persist_develop(trail: Trail): Observable<any> {
    return this.trailModel.persist_develop(trail);
  }

  persist_group(trail: Trail): Observable<any> {
    return this.trailModel.persist_group(trail);
  }

  delete(id: number): Observable<any> {
    return this.trailModel.delete(id);
  }

  setStatus(status: number, id: number): Observable<any> {
    return this.trailModel.setStatus({ status }, id);
  }

  sendTrailNotification(data, id: number): Observable<any> {
    return this.trailModel.sendTrailNotification(data, id);
  }

  setClass(id: number): Observable<any> {
    return this.trailModel.setClass(id);
  }

  saveData(trail: Trail): Observable<any> {
    return this.trailModel.saveData(trail);
  }

  update(trail: Trail): Observable<any> {
    return this.trailModel.update(trail);
  }

  persistTrailConfiguratorByGroupFilter(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/grupo/has/trail/filter', data, LibraryHelper.getHeaders());
  }

  persistTrailConfiguratorByFilterOnly(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/has/filter', data, LibraryHelper.getHeaders());
  }

  persistTrailConfiguratorByUserGroup(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/config/users', data, LibraryHelper.getHeaders());
  }

  persistTrailConfiguratorByUserOnly(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/has/user', data, LibraryHelper.getHeaders());
  }

  folderUserShared(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/shared/folder/admin', LibraryHelper.getHeaders());
  }

  featuredTrail(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/folder/destaque/' + data.id, data, LibraryHelper.getHeaders());
  }

  clientGroup(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/group/cad', LibraryHelper.getHeaders());
  }

  getGroup(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/filter/' + id, LibraryHelper.getHeaders());
  }

  getGroupFolder(id: any, folder: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/filter/' + id + '/' + folder, LibraryHelper.getHeaders());
  }
}
