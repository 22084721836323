import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-component-yes-no-config',
  templateUrl: 'yes-no-config.component.html'
})

export class EditorYesNoConfigComponent {
  @Input() customData: any;

  constructor() {
  }


}
