import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StarPointComponent } from 'src/app/@core/models/item-component';


declare var $: any;

@Component({
  selector: 'app-editor-component-star-point-config',
  templateUrl: 'star-point-config.component.html'
})

export class EditorStarPointConfigComponent {
  @Input() item: StarPointComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }
}
