import { Component, Input } from '@angular/core';
import { RatingComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-rating',
  templateUrl: 'rating.component.html',
})
export class EditorRatingComponent {
  @Input() item: RatingComponent;
}
