import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

export class SanitizerHelper {

  constructor(
    private _sanitizer: DomSanitizer
  ){}

  public sanitizer(url: string, type: string): SafeResourceUrl {

    let uri;

    if (!url) {
      return null;
    }

    if (type === 'youtube') {
      const code = this.getYoutubeID(url);
      uri = 'https://www.youtube.com/embed/' + code + '?rel=0&amp;showinfo=0';
    }

    if (type === 'vimeo') {
      const code = this.getVimeoID(url);
      uri = 'https://player.vimeo.com/video/' + code;
    }

    if (type === 'file') {
      uri = url
    }

    return this._sanitizer.bypassSecurityTrustResourceUrl(uri);
  }

  getYoutubeID(url: string): string {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : '';
  }

  getVimeoID(url: string): string {
// tslint:disable-next-line: max-line-length
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url.match(regExp);
    return (match && match[3]) ? match[3] : '';
  }

}

