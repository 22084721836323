import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { CertificateService } from 'src/app/@core/services/certificate/certificate.service';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

@Component({
  selector: 'app-editor-certificado',
  templateUrl: './certificado.component.html'
})

export class CertificadoComponent implements OnInit, OnChanges {
  @Input() certificateModel: any;
  @Output() modelSelected = new EventEmitter<any>();

  id: number;

  certificatesArray: Array<any>;

  isPosting: string;

  constructor(
    private route: ActivatedRoute,
    private certificateService: CertificateService,
    public translateService: TranslateSharedModule,
  ) {
  }

  // ngOnInit(): void {
  //   console.log()

  // }

  ngOnInit() {
    console.log('CertificadoComponent: this.certificateModel', this.certificateModel);
    this.getParams();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.certificateModel) {
      this.modelSelected.emit(changes.certificateModel.currentValue);
      console.log('CHANGES CERTIFICADO ->', this.certificateModel);
    }
  }

  getParams() {
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.getCertificates();
    });
  }

  getCertificates() {
    this.certificateService.getResource(this.id)
      .subscribe((res: any) => {
        this.certificatesArray = res.data;
      });
  }

  getPhoto($event: any) {
    this.isPosting = $event.path;
    this.certificateService.createResource({file: $event.path}, {router: this.id})
      .subscribe((res: any) => {
        this.certificatesArray.push(res.data);
        this.isPosting = null;
      });
  }

  async onRemove(certificate: any) {
    Swal.fire({
      title:await this.translateService.showTranslator('YouWantDeleteThisModel'),
      text: await this.translateService.showTranslator('YouWillNotReverseActionOnceConfirmed'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: await this.translateService.showTranslator('Yes'),
      cancelButtonText: await this.translateService.showTranslator('No')
    }).then(
    (result) => {
      if (result.value) {
        const index = this.certificatesArray.indexOf(certificate);
        this.certificatesArray.splice(index, 1);

        this.certificateService.deleteResource(certificate.id)
        .subscribe(res => {
          console.log(res);
          this.modelSelected.emit(this.certificateModel);
          // this.certificateModel.file = '';
        });
      }

    },
    (cancel) => {}
    );
  }

  certificateUpdated(model: any): void {
    this.modelSelected.emit(model);
  }

}
