import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { NouisliderModule } from 'ng2-nouislider';

import { CertificadoComponent } from './certificado.component';
import { CertificadoModeloComponent } from './modelos/default/default.component';
import { CertificadoConfigComponent } from './config/certificado-config.component';
import { MaterialDesignModule } from 'src/app/material.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { TranslateSharedModule } from 'src/app/translate/translate.module';
import { AsapUploadModule } from 'asap-upload';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    NouisliderModule,
    MaterialDesignModule,
    TranslateSharedModule,
    AsapUploadModule,
  ],
  declarations: [
    CertificadoComponent,
    CertificadoConfigComponent,
    CertificadoModeloComponent,
  ],
  exports: [
    CertificadoComponent,
    CertificadoConfigComponent,
    CertificadoModeloComponent,
    AsapUploadModule
  ]
})

export class CertificateModule { }
