import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../@core/services/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})

export class RedirectComponent {
  token: string;
  trail: string;
  lang;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public translate: TranslateService
  ) {
    this.activatedRoute.queryParams
      .subscribe((params: Params) => {
        this.token = params.token;
        this.trail = params.trail;
        this.lang = params.lang;
        this.authenticate();
      });
  }

  authenticate() {
    sessionStorage.setItem('apiURL', environment.apiUrl);
    localStorage.setItem('apiURL', environment.apiUrl);

    const data = {
      token: this.token
    };

    this.authService.setSessionToken(data)
      .then((response) => {
        this.router.navigate([this.trail], { queryParams: { lang: this.lang } });
      });
  }

}
