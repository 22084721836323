import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GalleryService } from '../../../@core/services/gallery.service';

@Component({
  selector: 'app-video-gallery-dialog',
  templateUrl: 'gallery-dialog.component.html',
  providers: [GalleryService]
})
export class GalleryDialogComponent implements OnInit {

  galleryFolderArray: any;

  galleryFolderSelect: any;
  galleryFileSelected: any;

  constructor(
    public galleryService: GalleryService,
    public dialogRef: MatDialogRef<GalleryDialogComponent>
  ) {

  }

  ngOnInit() {
    this.getGalleries();
  }

  getGalleries() {
    this.galleryService.getResources({ query: 'type=editor' })
      .subscribe((res: any) => {
        this.galleryFolderArray = res.data;
      });
  }

  galleryDialogClose(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.galleryFileSelected);
  }

}
