import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({name: 'decodeURI'})
export class DecodeURIPipe implements PipeTransform {

  public transform(value: string): string {
    return decodeURIComponent(value);
  }
}
