import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-component-form-step',
  templateUrl: 'form-step.component.html',
  styleUrls: ['./form-step.component.scss']
})

export class EditorFormStepComponent {
  @Input() item: any;

  constructor() {
  }

  onSubmit() {

  }

  getArray(length: number): Array<any> {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(i);
    }
    return arr;
  }

}
