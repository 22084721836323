import { Component, Input, OnInit } from '@angular/core';
import { FeedbackItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-feedback',
  templateUrl: 'feedback.component.html'
})
export class EditorFeedbackComponent implements OnInit {
  @Input() item: FeedbackItemComponent;
  @Input() id: any;

  loading = true;

  score = 0;
  displayRatingScore = 0;

  showAnswer: boolean;
  fakeAnswer: string;

  constructor(
  ) {

  }

  ngOnInit() {
  }

  onSubmit() {
    this.showAnswer = true;
  }

  vote() {
    console.log('FeedbackComponent: vote');
  }

}
