import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-component-download',
  templateUrl: 'download.component.html',
  styleUrls: ['./download.component.scss']
})

export class EditorDownloadComponent implements OnInit {

  @Input() item: any;

  constructor() { }

  ngOnInit() {
    console.log("[download], item", this.item);
   }

  goNext() {
    console.log('goNext');
  }

}
