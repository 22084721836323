import { Component } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { Global } from 'asap-crud';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})

export class AppComponent {

  constructor(
    public global: Global,
    public activatedRoute: ActivatedRoute,
    public firestorage: AngularFireStorage,
  ) {
    global.setSessionData('firebase-storage',firestorage.ref('/'));                
  }

}
