export class TrailSubStage {
  public id: number;
  public title: string;
  public stage: string;

  constructor(
    id: number,
    title: string
  ) {
    this.id = id;
    this.title = title;
  }
}
