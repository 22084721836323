import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';

import { EditorComponentService, COMPONENTS } from 'src/app/@core/services/editor-component.service';
import { StorageService } from 'src/app/@core/services/storage.service';
import { Trail } from 'src/app/@core/models/trail';
import { TrailStage } from 'src/app/@core/models/trail-stage';
import { TrailSubStage } from 'src/app/@core/models/trail-substage';
import { EditorComponent } from 'src/app/@core/models/editor-component.model';
import { TrailBaseService } from 'src/app/@core/services/trail-base.service';
import { TarefaService } from 'src/app/@core/services/tarefa.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material';
import { StageDialogComponent } from './stage-dialog/stage-dialog.component';
import { SubStageDialogComponent } from './sub-stage-dialog/sub-stage-dialog.component';
import { FeaturedDialogComponent } from './featured-dialog/featured-dialog.component';
import { SortableOptions } from 'sortablejs';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

@Component({
  selector: 'app-col-left',
  templateUrl: './col-left.component.html'
})
export class ColLeftComponent implements OnInit {

  @Input() public trail: Trail;
  @Input() public trailType: any;
  @Input() public id: number;
  @Input() public model: number;
  @Output() public insertItemEvent = new EventEmitter<any>();
  @Output() public subStageSelectedEvent = new EventEmitter<any>();
  @Output() public modelSelected = new EventEmitter<any>();
  @Output() public formatSelected = new EventEmitter<any>();

  loggedUser: any;

  trailStageArray: Array<TrailStage>;
  taskArray: any;
  formattedTaskArray: Array<{ id: number, title: string, tasks: any }>;

  public components = COMPONENTS;
  public componentArray: Array<EditorComponent>;

  trailStageSortableOptions: SortableOptions;
  trailSubStageSortableOptions: SortableOptions;

  format: string;

  selectedTrailSubStage: number;

  constructor(
    public storageService: StorageService,
    public trailService: TrailBaseService,
    public tarefaService: TarefaService,
    public dialog: MatDialog,
    public editorComponentService: EditorComponentService,
    public translateService: TranslateSharedModule,
  ) {
    this.format = 'components';
    this.loggedUser = storageService.get('loggedUser').data;

    this.selectedTrailSubStage = 0;

    this.trailStageSortableOptions = this.trailStageSortableOptionsFn();
    this.trailSubStageSortableOptions = this.trailSubStageSortableOptionsFn();
  }

  ngOnInit() {
    console.log('ColLeftComponent: model', this.model);
    this.getComponentPermissions();
    this.getStages();
  }

  trailStageSortableOptionsFn() {
    return {
      onUpdate: (event: any) => {
        const newSort = [];
        for (const i of this.trailStageArray) {
          newSort.push(i.id);
        }
        const newSortString = newSort.join(',');
        this.orderStagesTo(newSortString);
      }
    };
  }

  trailSubStageSortableOptionsFn() {
    return {
      onUpdate: (event: any) => {
        const divId = event.item.id;
        const id: number = +divId.split('-')[1];

        const trailStage = this.trailStageArray.find((item) => {
          return item.id === id;
        });

        const index = this.trailStageArray.indexOf(trailStage);

        const newSort = [];

        for (const i of this.trailStageArray[index].substages) {
          newSort.push(i.id);
        }

        const newSortString = newSort.join(',');

        this.orderSubStagesTo(newSortString);
      }
    };
  }

  orderStagesTo(order: string) {
    this.trailService.orderStages(order)
      .subscribe(res => {
        console.log(res);
      });
  }

  orderSubStagesTo(order: string) {
    this.trailService.orderSubStages(order)
      .subscribe(res => {
        console.log(res);
      });
  }

  getComponentPermissions(): void {
    this.editorComponentService.collection(this.loggedUser.client_id)
      .then(res => {
        console.log('getComponentPermissions: res', res);
        this.componentArray = res;
      });
  }

  getStages(): void {
    this.trailService.fetchStages(this.id).subscribe((res: any) => {

      this.trailStageArray = [];

      for (const item of res.data) {
        const trailStage = new TrailStage(item.id, item.nome, item.image);
        trailStage.enable = item.enable;
        trailStage.sinopse = item.sinopse;
        trailStage.time = item.time;
        trailStage.rotulo = item.rotulo;
        trailStage.text_rotulo = item.text_rotulo;

        trailStage.substages = [];

        item.subEtapa.data.sort(this.compare);

        for (const subs of item.subEtapa.data) {
          const trailSubStage = new TrailSubStage(subs.id, subs.nome);
          trailSubStage.stage = item.id;
          trailStage.substages.push(trailSubStage);
        }

        this.trailStageArray.push(trailStage);
      }

      this.getTasks();
    });
  }

  getTasks() {
    this.tarefaService.getResource(this.id)
      .subscribe(res => {
        this.taskArray = res;
        this.generateTaskData();
      });
  }

  generateTaskData() {
    this.formattedTaskArray = [];

    this.trailStageArray.map(st => {

      const tasksFiltered = this.taskArray.filter((i: any) => +i.etapa_id === st.id);

      this.formattedTaskArray.push({
        id: st.id,
        title: st.title,
        tasks: tasksFiltered
      });

    });
  }

  compare(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  insertItem(type: string) {
    console.log('[newComponent] insertItem ->', type)
    this.insertItemEvent.emit(type);
  }

  async addStage() {
    if (this.componentArray['type_' + this.trailType].qtdModulos) {
      const qtd = parseInt(this.componentArray['type_' + this.trailType].qtdModulos, 10);
      if (qtd > this.trailStageArray.length) {
        this.showStageDialog(false);
      } else {
        Swal.fire({
          title: await this.translateService.showTranslator('AddModule'),
          text:  await this.translateService.showTranslator('YouIncludedMaximumModules'),
          icon: 'warning'
        });
      }
    } else {
      this.showStageDialog(false);
    }
  }

  updateStage(stage: TrailStage) {
    this.showStageDialog(stage);
  }

  showStageDialog(stage: TrailStage | boolean) {
    const dialogRef = this.dialog.open(StageDialogComponent, {
      data: {
        id: this.id,
        stage
      }
    });

    dialogRef.afterClosed().subscribe((result: TrailStage) => {
      console.log(result);
      if (result) {
        const trail = this.trailStageArray.find(item => {
          return item.id === result.id;
        });

        if (trail) {
          trail.title = result.title;
          trail.sinopse = result.sinopse;
          trail.time = result.time;
          trail.image = result.image;
        } else {
          this.trailStageArray.push(result);
        }

      }
    });
  }

  showSubStageDialog(substage: TrailSubStage | boolean) {
    const dialogRef = this.dialog.open(SubStageDialogComponent, {
      data: {
        substage,
        trailStageArray: this.trailStageArray
      }
    });

    dialogRef.afterClosed().subscribe((result: TrailSubStage) => {
      console.log(result);
      if (result) {
        const stageFound = this.trailStageArray.find(item => +item.id === +result.stage);
        const iStageFound = this.trailStageArray.indexOf(stageFound);

        const subStageFound = this.trailStageArray[iStageFound].substages.find(item => +item.id === +result.id);

        if (subStageFound) {
          const iSubsStageFound = this.trailStageArray[iStageFound].substages.indexOf(subStageFound);
          this.trailStageArray[iStageFound].substages[iSubsStageFound] = result;
        } else {
          this.trailStageArray[iStageFound].substages.push(result);

          const newSort = [];

          for (const i of this.trailStageArray[iStageFound].substages) {
            newSort.push(i.id);
          }

          const newSortString = newSort.join(',');

          this.orderSubStagesTo(newSortString);
        }

        this.selectSubStage(stageFound, result);

        // this.getStages();
      }
    });
  }

  showFeaturedModal(stage: TrailStage) {
    const dialogRef = this.dialog.open(FeaturedDialogComponent, {
      data: {
        id: this.id,
        stage
      }
    });

    dialogRef.afterClosed().subscribe((result: TrailStage) => {
      console.log(result);
      if (result) {
        stage = result;
      }
    });
  }

  async deleteStage(trailStage: TrailStage) {

    Swal.fire({
      title: await this.translateService.showTranslator('YouWantDelete') +' "' + trailStage.title + '"?',
      text: await this.translateService.showTranslator('YouWillNotReverseActionOnceConfirmed'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0d0d80',
      cancelButtonColor: '#00d56f',
      confirmButtonText: await this.translateService.showTranslator('Yes'),
      cancelButtonText: await this.translateService.showTranslator('No')
    }).then(
      (result) => {
        if (result.value) {
          const index = this.trailStageArray.indexOf(trailStage);
          this.trailStageArray.splice(index, 1);

          this.trailService.deleteStage(trailStage.id)
            .subscribe((res: any) => {
              console.log(res);
            });
        }

      },
      (cancel) => { }
    );
  }

  async deleteSubStage(trailStage: TrailStage, trailSubStage: TrailSubStage) {

    Swal.fire({
      title: await this.translateService.showTranslator('YouWantDelete') +' "' + trailSubStage.title + '"?',
      text: await this.translateService.showTranslator('YouWillNotReverseActionOnceConfirmed'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0d0d80',
      cancelButtonColor: '#00d56f',
      confirmButtonText: await this.translateService.showTranslator('Yes'),
      cancelButtonText: await this.translateService.showTranslator('No'),
    }).then(
      (result) => {
        if (result.value) {
          this.selectedTrailSubStage = 0;
          this.subStageSelectedEvent.emit({ stage: null, substage: null });


          const index = trailStage.substages.indexOf(trailSubStage);
          trailStage.substages.splice(index, 1);

          this.trailService.deleteSubStage(trailSubStage.id)
            .subscribe(res => {
              console.log(res);
            });
        }
      },
      (cancel) => { }
    );
  }

  selectSubStage(trailStage: TrailStage, trailSubStage: TrailSubStage) {
    this.selectedTrailSubStage = trailSubStage.id;
    this.subStageSelectedEvent.emit({ stage: trailStage, substage: trailSubStage });
  }

  activate(stage: TrailStage) {
    if (!stage.activating) {
      stage.activating = true;
      this.trailService.activateStage(stage.id)
        .subscribe((response: any) => {
          stage.enable = !stage.enable;
          delete (stage.activating);
        });
    }
  }

  setFormat(format: string): void {
    this.format = format;
    this.formatSelected.emit(format);
  }

  getModelEvent(model: any): void {
    this.modelSelected.emit(model);
  }

  isLocalhost() {
    let host = window.location.hostname;
    return host === 'localhost';
  }

}
