import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DownloadComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-download-config',
  templateUrl: 'download-config.component.html'
})

export class EditorDownloadConfigComponent {

  @Input() item: DownloadComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  setAlign(element: any, align: string) {
    element.align = align;
  }

  getArchive($event: any) {
    this.item.file = $event.path;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
