import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ForumEditorService } from 'src/app/@core/services/forum.editor';

@Component({
  selector: 'app-editor-component-forum-config',
  templateUrl: 'forum-config.component.html'
})
export class EditorForumConfigComponent implements OnInit {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public itemChange = new EventEmitter<any>();

  forumArray: any;

  constructor(
    private route: ActivatedRoute,
    private forumEditorService: ForumEditorService
  ) {
  }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      if (params.id) {
        const id = params.id;
        this.forumEditorService.getResource(id)
          .subscribe((res: any) => {
            this.forumArray = res.data;
          });
      }
    });
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
