import { TrailSubStage } from './trail-substage';

export class TrailStage {
  public id: number;
  public title: string;
  public sinopse: string;
  public time: string;
  public image: string;
  public substages: Array<TrailSubStage>;
  public enable: boolean;
  public activating: boolean;
  public rotulo: any;
  public text_rotulo: any;

  constructor(
      id: number,
      title: string,
      image: string
  ) {
    this.id = id;
    this.title = title;
    this.image = image;
  }

}
