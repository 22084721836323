import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StarPointComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-star-point',
  templateUrl: 'star-point.component.html',
})

export class EditorStarPointComponent {
  @Input() item: StarPointComponent;
  @Output() public event = new EventEmitter<any>();

  onDone() {
    this.event.emit();
  }

}
