import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IntroComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-intro',
  templateUrl: 'intro.component.html'
})
export class EditorIntroComponent implements OnInit {
    @Input() item: IntroComponent;
    @Output() event = new EventEmitter<any>();

    client: string;
    loggedUser: any;

    ngOnInit() {
      this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    }

    goNext() {
      this.event.emit();
    }

}
