import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'youtubeEmbed' })
export class YoutubeEmbedPipe implements PipeTransform {
  constructor() { }

  transform(url: any): string {
    return '//www.youtube.com/embed/' + this.getId(url);
  }

  getId(url: string) {
    if (!url) {
      return '4e6fd6c0-b568-11ea-b5a5-01c8d6a31f9d';
    }
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2] && match[2].length === 11) {
      return match[2];
    } else {
      return 'error';
    }
  }
}
