import { Component, Input, OnInit } from '@angular/core';
import { CorrectIncorrectComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-sheet-correct-incorrect',
  templateUrl: 'sheet-correct-incorrect.component.html',
  styleUrls: ['./sheet-correct-incorrect.component.scss']
})

export class EditorSheetCorrectIncorrectComponent implements OnInit {
  @Input() item: CorrectIncorrectComponent;
  @Input() id: number;
  public view;

  public sended: boolean;


  ngOnInit() {
    for (const i of this.item.lines) {
      i.selected = 'nao';
    }
  }

  onSubmit() {
    this.sended = true;
  }
}
