import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-col-right',
    templateUrl: './col-right.component.html'
})

export class ColRightComponent  {
  @Input() item: any;
  @Input() customData: any;
  @Input() format: string;
  @Input() certificate: string;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadPlayer = new EventEmitter<any>();
  @Output() public itemChange = new EventEmitter<any>();

  emitSendEvent() {
    this.sendEvent.emit();
  }

  reloadPlayerEvent() {
    this.reloadPlayer.emit();
  }

}
