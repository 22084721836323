import { Component, Input } from '@angular/core';
import { ImageItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-image',
  templateUrl: 'image.component.html',
})
export class EditorImageComponent {
  @Input() item: ImageItemComponent;
}
