import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class StorageService {

  constructor() {}

  set(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  get(key: string, parse = true): any {
    const ss = sessionStorage.getItem(key);
    if (ss) {
      if (parse) {
        return JSON.parse(ss);
      }
      return ss;
    }

    const ls = localStorage.getItem(key);
    if (ls) {
      if (parse) {
        return JSON.parse(ls);
      }
      return ls;
    }

    return false;
  }

}
