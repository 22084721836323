import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

declare var $: any;

@Component({
  selector: 'app-editor-component-contract-config',
  templateUrl: 'contract-config.component.html'
})
export class EditorContractConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  public editorOptions = {
    // tslint:disable-next-line: max-line-length
    plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern help',
    // tslint:disable-next-line: max-line-length
    toolbar: 'styleselect | bold italic forecolor | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | fullscreen | saveButton',
    branding: false,
    placeholder:"Texto aqui",
    height: 500,
    menubar: false,
    statusbar: false,
    image_advtab: true,
    images_upload_handler: (blobInfo: any, success: any, failure: any) => {
      // this._fileManagerService.uploadFile(blobInfo.blob())
      //   .subscribe(event => {
      //     console.log(event);
      //     if (event instanceof HttpResponse) {
      //       success(event.body.data.url);
      //     }
      // }, err => {
      //   failure(err);
      // });
    },
    setup: async (editor: any) =>  {
      editor.ui.registry.addButton('saveButton', {
        icon: 'save',
        tooltip: await this.translateService.showTranslator('Save'),
        onAction: async () => {
          alert(await this.translateService.showTranslator('SavedSuccessfully'));
          this.emitSendEvent();
        }
      });
    }
  };

  constructor(private translateService: TranslateSharedModule
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

}
