import { Component, Input } from '@angular/core';
import { TextItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-text',
  templateUrl: 'text.component.html',
  styleUrls: ['./text.component.scss']
})
export class EditorTextComponent {
  @Input() item: TextItemComponent;
}
