import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EditorComponent } from '../models/editor-component.model';
import { environment } from 'src/environments/environment';
import { LibraryHelper } from '../helpers/library.helper';

export const COMPONENTS: Array<EditorComponent> = [

  {
    icon: 'list-check',
    component: 'avaliation',
    label: 'Avaliation',
    enabled: false
  },
  {
    icon: 'list-stars',
    component: 'rating',
    label: 'Avaliation',
    enabled: false
  },
  {
    icon: 'calculator',
    component: 'exercise',
    label: 'Calculadoras',
    enabled: false
  },
  {
    icon: 'check-circle',
    component: 'conclusion',
    label: 'Conclusion',
    enabled: false
  },
  {
    icon: 'file-text',
    component: 'contract',
    label: 'Contrato',
    enabled: false
  },
  {
    icon: 'check2-square',
    component: 'correct-incorrect',
    label: 'CorrectIncorrect',
    enabled: false
  },
  {
    icon: 'puzzle',
    component: 'challenge',
    label: 'Challenge',
    enabled: false
  },
  {
    icon: 'download',
    component: 'download',
    label: 'Download',
    enabled: false
  },
  {
    icon: 'files',
    component: 'form-step',
    label: 'Form',
    enabled: false
  },
  {
    icon: 'question',
    component: 'forum',
    label: 'Fórum (externo)',
    enabled: false
  },
  {
    icon: 'question-circle',
    component: 'feedback',
    label: 'Fórum',
    enabled: true
  },
  {
    icon: 'card-image',
    component: 'image',
    label: 'Image',
    enabled: false
  },
  {
    icon: 'flag',
    component: 'intro',
    label: 'Intro',
    enabled: false
  },
  {
    icon: 'list-task',
    component: 'multi-choice',
    label: 'MultipleChoice',
    enabled: false
  },
  {
    icon: 'text-paragraph',
    component: 'text',
    label: 'Paragraph',
    enabled: false
  },
  {
    icon: 'file-pdf',
    component: 'pdf',
    label: 'PDF',
    enabled: false
  },
  {
    icon: 'star-half',
    component: 'star-point',
    label: 'Score',
    enabled: false
  },
  {
    icon: 'list-ul',
    component: 'multiple-questions',
    label: 'MultipleQuestion',
    enabled: false
  },
  {
    icon: 'table',
    component: 'table',
    label: 'Table',
    enabled: false
  },
  {
    icon: 'upload',
    component: 'upload',
    label: 'Upload',
    enabled: false
  },
  {
    icon: 'caret-right',
    component: 'video',
    label: 'Vídeo',
    enabled: false
  },
  {
    icon: 'volume-up',
    component: 'audio',
    label: 'Áudio',
    enabled: false
  },
  {
    icon: 'question-square',
    component: 'random-questions',
    label: 'RandomQuestion',
    enabled: false
  },
  {
    icon: 'search',
    component: 'research',
    label: 'Pesquisa',
    enabled: false
  },
  {
    icon: 'file-earmark-break',
    component: 'page',
    label: 'Página',
    enabled: false
  }
];

@Injectable({ providedIn: 'root' })
export class EditorComponentService {

  constructor(public http: HttpClient) { }

  getComponentPermissions(id: number): Promise<Array<{ 'resource': string, enabled: boolean }>> {
    return new Promise<Array<{ 'resource': string, enabled: boolean }>>((resolve, reject) => {
      this.fetchAll(id).subscribe(res => {
        if (res.data && res.data.length > 0) {
          if (res.data[0].resources) {
            resolve(res.data[0].resources);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      }, err => {
        reject(err);
      });
    });
  }

  public collection(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fetchAll(id).subscribe((response: any) => {
        if (response.data.length === 0) {
          const permissions = COMPONENTS;
          this.trailTypes().subscribe((types: any) => {
            const permissionByType = {};
            const typesLength = types.length;
            for (let i = 0; i < typesLength; i++) {
              permissionByType['type_' + types[i].type] = {};

              const permissionsLength = permissions.length;
              for (let j = 0; j < permissionsLength; j++) {
                permissionByType['type_' + types[i].type][permissions[j].component] = false;
              }
            }
            resolve(permissionByType);
          });
        } else {
          const permissions = response.data[0].resources;
          if (Array.isArray(permissions)) {
            this.trailTypes().subscribe((types: any) => {
              const permissionByType = {};
              const typesLength = types.length;
              for (let i = 0; i < typesLength; i++) {
                permissionByType['type_' + types[i].type] = {};

                const permissionsLength = permissions.length;
                for (let j = 0; j < permissionsLength; j++) {
                  permissionByType['type_' + types[i].type][permissions[j].resource] = permissions[j].enabled;
                }
              }
              resolve(permissionByType);
            });
          } else {
            resolve(permissions);
          }
        }
      });
    });
  }

  public fetchAll(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/editor/' + id, LibraryHelper.getHeaders());
  }

  public persist(id: number, data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/editor/' + id, { resources: data }, LibraryHelper.getHeaders());
  }

  public trailTypes() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/types', LibraryHelper.getHeaders());
  }

}
