import { Component, Input, Output, EventEmitter } from '@angular/core';

import { CorrectIncorrectComponent, CorrectIncorrectComponentLines } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-sheet-correct-incorrect-config',
  templateUrl: 'sheet-correct-incorrect-config.component.html'
})

export class EditorSheetCorrectIncorrectConfigComponent {
  @Input() item: CorrectIncorrectComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  setAlign(element: any, align: string) {
    element.align = align;
  }

  setElementAlign(element: any, align: string) {
    this.item[element] = align;
  }

  insertLine() {
    if (!this.item.lines) {
      this.item.lines = [];
    }
    this.item.lines.push(new CorrectIncorrectComponentLines());
  }

  removeLine(index: number) {
    this.item.lines.splice(index, 1);
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
