import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MultiChoiceComponent, MultiChoiceOptions } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-multichoice-config',
  templateUrl: 'multichoice-config.component.html'
})
export class EditorMultichoiceConfigComponent {
  @Input() item: MultiChoiceComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
    public domSanitizer: DomSanitizer
  ) {
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  setElementAlign(element: any, align: string) {
    this.item[element] = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  multiChoiceMediaChanged() {
    console.log('multiChoiceMediaChanged');
  }

  correctUpdated(option: MultiChoiceOptions) {
    this.item.options.map(i => i.correct = false);
    option.correct = true;
  }

  insertOption(item: MultiChoiceComponent) {
    item.options.push(new MultiChoiceOptions());
  }

  removeOption(option: MultiChoiceOptions, item: MultiChoiceComponent) {
    const index = item.options.indexOf(option);
    item.options.splice(index, 1);
  }

  getPhoto($event: { path: string }) {
    this.item.url = $event.path;
  }


}
