import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TableComponent, CollumnTable } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-dynamic-table-config',
  templateUrl: 'dynamic-table-config.component.html'
})

export class EditorDynamicTableConfigComponent {
    @Input() item: TableComponent;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    title = new FormControl();

    setAlign(element: any, align: string) {
        element.align = align;
    }

    emitSendEvent() {
      this.sendEvent.emit();
    }

    createNewCollumn() {
        if (!this.item.collumns) {
            this.item.collumns = [];
        }
        this.item.collumns.push(new CollumnTable());
    }

    removeCollumn(index: number) {
      this.item.collumns.splice(index, 1);
    }

    getMediaPhoto($event: {path: string}) {
      this.item.mediaUrl = $event.path;
    }

}
