import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IntroComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-intro-config',
  templateUrl: 'intro-config.component.html'
})
export class EditorIntroConfigComponent {
  @Input() item: IntroComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
