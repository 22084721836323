import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { PipeModule } from 'src/app/@core/pipes/pipe.module';
import { MaterialDesignModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';


import { EditorSharedButtonComponent } from './button/button.component';
import { EditorSharedButtonConfigComponent } from './button/config/button-config.component';
import { EditorSharedSubTitleConfigComponent } from './subtitle/config/subtitle-config.component';
import { EditorSharedSubTitleComponent } from './subtitle/subtitle.component';
import { EditorSharedTitleConfigComponent } from './title/config/title-config.component';
import { EditorSharedTitleComponent } from './title/title.component';
import { EditorSharedAnimationComponent } from './animation-select/animation-select.component';
import { EditorSharedMediaComponent } from './media/media.component';
import { EditorSharedMediaConfigComponent } from './media/config/media-config.component';

const EDITOR_COMPONENTS = [
  EditorSharedTitleComponent,
  EditorSharedTitleConfigComponent,
  EditorSharedSubTitleComponent,
  EditorSharedSubTitleConfigComponent,
  EditorSharedButtonComponent,
  EditorSharedButtonConfigComponent,
  EditorSharedAnimationComponent,
  EditorSharedMediaComponent,
  EditorSharedMediaConfigComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    MaterialDesignModule,
    PipeModule,
    SharedModule
  ],
  declarations: [
    ... EDITOR_COMPONENTS
  ],
  exports: [
    ... EDITOR_COMPONENTS
  ]
})

export class SharedComponentsModule { }
