import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MultipleQuestionComponent, MultipleQuestion, MultipleQuestionOption } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-multiple-questions-config',
  templateUrl: 'multiple-questions-config.component.html',
})

export class EditorMultipleQuestionsConfigComponent {
  @Input() item: MultipleQuestionComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  setAlign(element: any, align: string) {
    element.align = align;
  }

  insertQuestion() {
      this.item.questions.push(new MultipleQuestion());
  }

  insertOption(questionIndex: number) {
      this.item.questions[questionIndex].options.push(new MultipleQuestionOption());
  }

  removeQuestion(index: number): void {
      this.item.questions.splice(index, 1);
  }

  removeOption(index: number, indexOption: number): void {
      this.item.questions[index].options.splice(indexOption, 1);
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto(option, $event) {
    option.image = $event.path;
  }

}
