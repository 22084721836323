import { Guid } from 'guid-typescript';

export class ItemComponent {
  public type: string;
  public background: string;
  public title: SharedTitleItemComponent;
  public subTitle: SharedSubTitleItemComponent;
  public text: SharedTextItemComponent;
  public button: SharedButtonItemComponent;
  public media: SharedMediaItemComponent;
  public active: boolean;

  constructor() {
    this.background = '#ffffff';
    this.button = new SharedButtonItemComponent();
    this.title = new SharedTitleItemComponent();
    this.subTitle = new SharedSubTitleItemComponent();
    this.text = new SharedTextItemComponent();
    this.media = new SharedMediaItemComponent();
  }

  setData(data: any) {
    for (const i in data) {
      if (data[i] || data[i] === null) {
        this[i] = data[i];
      }
    }
  }
}

export class AvaliationItemComponent extends ItemComponent {

  public questions: Array<any>;
  public verification: string;

  public title: SharedTitleItemComponent;
  public subTitle: SharedSubTitleItemComponent;

  constructor() {
    super();
    this.title = new SharedTitleItemComponent();
    this.subTitle = new SharedSubTitleItemComponent();
    this.type = 'avaliation';

    this.questions = [];
    this.verification = 'no-action';
    this.background = '';
  }
}

export class RandomQuestionsItemComponent extends ItemComponent {
  public id: string;
  public questions: Array<any>;
  public title: SharedTitleItemComponent;
  public subTitle: SharedSubTitleItemComponent;

  constructor() {
    super();
    this.title = new SharedTitleItemComponent();
    this.subTitle = new SharedSubTitleItemComponent();
    this.id = Guid.raw();
    this.type = 'random-questions';

    this.questions = [];
    this.background = '';
  }
}

export class ResearchComponent extends ItemComponent {
  constructor() {
    super();
    this.type = 'research';
  }
}

export class RatingComponent extends ItemComponent {
  public positive: string;
  public negative: string;
  public slider: string;

  constructor() {
    super();
    this.type = 'rating';

    this.positive = 'Gostei';
    this.negative = 'Não gostei';
    this.slider = '#6C63FE';
  }
}
export class ExerciseComponent extends ItemComponent {
  public exercise: number;

  constructor() {
    super();

    this.type = 'exercise';
  }

}
export class ConclusionComponent extends ItemComponent {
  public icon: { size: string, animation: string, color: string };

  public featureColor: string;
  public feedback: string;

  constructor() {
    super();
    this.type = 'conclusion';

    this.icon = { size: '60', animation: 'none', color: '#6C63FE' };
    this.feedback = 'icon';
    this.featureColor = '';
  }
}
export class ContractComponent extends ItemComponent {
  public acceptText: string;

  constructor() {
    super();
    this.type = 'contract';

    this.acceptText = 'Concordo com este contrato';
  }
}

export enum ECorrectIncorrectFeedback {
  general = 'GENERAL'
}

export class CorrectIncorrectComponent extends ItemComponent {

  public mediaTitle: SharedTitleItemComponent;
  public mediaSubTitle: SharedSubTitleItemComponent;

  public lines: Array<CorrectIncorrectComponentLines>;
  public feedback: string;
  public feedbackMessage: string;
  public feedbackPositiveMessage: string;
  public feedbackNegativeMessage: string;
  public indicador: string;
  public indicadorType: string;

  constructor() {
    super();
    this.type = 'correct-incorrect';
    this.feedback = ECorrectIncorrectFeedback.general;
    this.lines = [];
    this.mediaTitle = new SharedTitleItemComponent();
    this.mediaSubTitle = new SharedSubTitleItemComponent();
    this.indicadorType = 'SINGLE';
  }
}

export class CorrectIncorrectComponentLines {
  public title: string;
  public feedback: string;
  public correct: boolean;
  public selected: string;

  constructor() {
    this.title = '';
    // this.feedback = 'left';
    this.correct = false;
    this.selected = 'nao'
  }
}

export class ChallengeComponent extends ItemComponent {
  public notes_title: { text: string, align: string };
  public notes_text: { text: string, align: string };
  public medias: Array<ChallengeMedia>;

  constructor() {
    super();
    this.type = 'challenge';
    this.notes_title = { text: '', align: 'left' };
    this.notes_text = { text: '', align: 'left' };
    this.medias = [];
  }
}

export class ChallengeMedia {
  public type: string;
  public text: { text: string, align: string };
  public url: string;
  public thumb: string;
  public image: string;
  public video: string;
  public title: string;
  public description: string;
  public featured: boolean;

  constructor() {
    this.featured = false;
    this.text = { text: '', align: 'left' };
  }
}

export class DownloadComponent extends ItemComponent {
  public layout: string;
  public file: string;
  public icon: {name: string, color: string};
  public iconColor: string;
  public iconSize: number;
  public downloadButton: SharedButtonItemComponent;

  constructor() {
    super();
    this.type = 'download';

    this.layout = '1';
    this.text.text = 'Este é um texto modelo do componente... no menu de configurações ao lado clique na guia "conteúdo", logo em seguida vá até o box "Caixa de Texto" e informe o seu texto, cor, efeito de animação, tamanho e alinhamento.';
    this.icon = {name: 'insert', color: '#000000'};
    this.iconColor = '#333333';
    this.iconSize = 6;
    this.downloadButton = new SharedButtonItemComponent();
    this.downloadButton.text = 'BAIXAR';
  }
}

export class FormStepComponent extends ItemComponent {
  public enunciated: { align: string, size: string, animation: string, color: string };
  public steps: Array<any>;

  constructor() {
    super();
    this.type = 'form-step';

    this.enunciated = { align: 'center', size: '16', animation: 'none', color: '#999999' };
    this.steps = [];
  }
}

export class ForumComponent extends ItemComponent {
  public forum: number;

  constructor() {
    super();
    this.type = 'forum';
  }

}

export class FeedbackItemComponent extends ItemComponent {

  public question: string;

  public stepDisplay: boolean;
  public firstStep: string;
  public secondStep: string;

  constructor() {
    super();
    this.type = 'feedback';
  }

}

export class ImageItemComponent extends ItemComponent {
  public url: string;

  constructor() {
    super();
    this.type = 'image';
    this.url = '';
  }

}

export class AudioItemComponent extends ItemComponent {

  public url: string;
  public cover: string;

  constructor() {
    super();
    this.type = 'audio';
  }

}
export class IntroComponent extends ItemComponent {
  public settings: { background: string, color: string, size: number };

  constructor() {
    super();
    this.type = 'intro';

    this.settings = { background: '#FFFFFF', color: '', size: 14, };
  }
}

export class MultiChoiceComponent extends ItemComponent {
  public type = 'multi-choice';
  public url: string;
  public feedback: string;
  public feedback_text: string;
  public options: Array<MultiChoiceOptions>;
  public answersAlign: string;
  public marketType: string;
  public verification: string;
  public color: string;
  public indicador: string;
  public subTitle: SharedSubTitleItemComponent;

  public constructor() {
    super();
    this.feedback = 'GENERAL';
    this.options = [];
    this.background = '';
    this.subTitle = new SharedSubTitleItemComponent();
  }
}

export class MultiChoiceOptions {
  public label: string;
  public correct: boolean;
  public selected: boolean;
  public value: string;
  public messageInError: string;

  public constructor() {
    this.label = '';
    this.correct = false;
    this.selected = false;
    this.value = this.uuidv4();
    this.messageInError = '';
  }

  uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}

export class TextItemComponent extends ItemComponent {
  public text: SharedTextItemComponent;
  public background: string;

  constructor() {
    super();

    this.type = 'text';
    this.background = '';
  }
}

/*************************
***** Componente: PDF ****
*************************/
export class PdfItemComponent extends ItemComponent {
  public type = 'pdf';
  public url: string;
  public acceptText: string;
  public showAccept: boolean;

  constructor() {
    super();

    this.type = 'pdf';
    this.showAccept = false;
  }

}

export class StarPointComponent extends ItemComponent {

  constructor() {
    super();
    this.type = 'star-point';
  }
}

export class MultipleQuestionComponent extends ItemComponent {

  public media: any;
  public mediaUrl: any;
  public mediaTitle: any;
  public questions: Array<MultipleQuestion>;

  constructor() {
    super();
    this.type = 'multiple-questions';
    this.questions = [];
  }
}

export class MultipleQuestion {
  public title: string;
  public indicador: string;
  public options: Array<MultipleQuestionOption>;

  constructor() {
    this.title = '';
    this.options = [];
  }
}

export class MultipleQuestionOption {
  public image: string;
  public text: string;
  public correct: boolean;

  constructor() {
    this.image = '';
    this.text = '';
    this.correct = false;
  }
}

export class TableComponent extends ItemComponent {
  public type: string;

  public mediaTitle: string;
  public mediaUrl: string;

  public width: number;
  public help: any;
  public userActive: boolean;
  public borderActive: boolean;
  public stripedActive: boolean;
  public importData: boolean;
  public exportData: boolean;
  public countLines: number;
  public collumns: Array<CollumnTable>;
  public data: Array<any>;


  constructor() {
    super();
    this.type = 'table';

    this.mediaTitle = 'Legenda da mídia';

    this.userActive = false;
    this.borderActive = true;
    this.stripedActive = true;
    this.importData = false;
    this.exportData = false;
    this.countLines = 1;
    this.collumns = [];
    this.help = {
      type: 'none',
      text: '',
      url: ''
    };
    this.data = [];
  }
}

/*******************************************************
***** Componente: Tabela Dinâmica/Inputs Dinâmicos *****
*******************************************************/
export class CollumnTable {
  public title: string;
  public align: string;
  public placeholder: string;
  public selectOptions: Array<any>;
  public footer: string;
  public readonly: boolean;
  public width: number;
  public type: string;
  public reference_form: number;
  public reference_component: number;
  public reference_component_col: number;

  constructor() {
    this.title = 'Coluna';
    this.placeholder = '';
    this.align = 'left';
    this.selectOptions = [];
    this.footer = 'none';
    this.readonly = false;
    this.width = 50;
  }
}

export class UploadComponent extends ItemComponent {
  public file: any;
  public button_bg;

  constructor() {
    super();
    this.type = 'upload';
    this.file = [];
  }
}

export class UploadListComponent extends ItemComponent {
  public fileList: { title: string, required: boolean }[];

  constructor() {
    super();
    this.type = 'upload-list';
    this.fileList = [];
  }
}

export class VideoItemComponent extends ItemComponent {
  public source: string;
  public source_path: string;
  public showVideoControl: boolean;
  public allowNext: boolean;
  public poster: string;
  public button: SharedButtonItemComponent;
  public title: SharedTitleItemComponent;
  public subTitle: SharedSubTitleItemComponent;

  constructor() {
    super();
    this.title = new SharedTitleItemComponent();
    this.subTitle = new SharedSubTitleItemComponent();
    this.type = 'video';
    this.source = 'youtube';
    this.source_path = 'https://www.youtube.com/watch?v=ScMzIvxBSi4';
    this.showVideoControl = false;
    this.allowNext = false;
    this.button = {
      text: 'Próximo',
      color: '',
      background: '',
      hide: false
    }
  }

}

export class SharedTitleItemComponent {
  public text: string;
  public size: string;
  public color: string;
  public align: string;
  public animation: string;

  constructor() {
    this.setData({
      text: 'Título aqui',
      size: '32',
      color: '',
      align: 'center',
      animation: 'none'
    });
  }

  setData(data: any) {
    this.text = data.text;
    this.size = data.size;
    this.color = data.color;
    this.align = data.align;
    this.animation = data.animation;
  }
}
export class SharedSubTitleItemComponent {
  public text: string;
  public size: string;
  public color: string;
  public align: string;
  public enable: boolean;
  public animation: string;

  constructor() {
    this.setData({
      text: 'Subtitulo aqui',
      size: '18',
      color: '',
      align: 'center',
      enable: false,
      animation: 'none'
    });
  }

  setData(data: any) {
    this.text = data.text;
    this.size = data.size;
    this.color = data.color;
    this.align = data.align;
    this.enable = data.enable;
    this.animation = data.animation;
  }

}

export class SharedTextItemComponent {
  public text: string;
  public size: string;
  public color: string;
  public align: string;
  public animation: string;

  constructor() {
    this.setData({
      text: '',
      size: '32',
      color: '',
      align: 'center',
      animation: 'none'
    });
  }

  setData(data: any) {
    this.text = data.text;
    this.size = data.size;
    this.color = data.color;
    this.align = data.align;
    this.animation = data.animation;
  }
}

export class SharedButtonItemComponent {
  public text: string;
  public color: string;
  public background: string;
  public hide: boolean;

  constructor() {
    this.text = 'Próximo';
    this.color = '';
    this.background = '';
    this.hide = false;
  }
}



const enum SharedMediaTypes {
  none = 'NONE',
  image = 'IMAGE',
  youtube = 'YOUTUBE',
  vimeo = 'VIMEO'
}

export class SharedMediaItemComponent {
  public type: SharedMediaTypes;
  public url: string;

  constructor() {
    this.type = SharedMediaTypes.none;
    this.url = '';
  }
}

export class PageItemComponent extends ItemComponent {

  public type = 'page';
  public content: any = {};
  
  constructor() {
    super();
  }

}