import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class FileService {

  constructor(protected http: HttpClient) {
  }

  uploadFile(data: any): Observable<HttpEvent<{}>> {

    const formData: FormData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('description', data.description);

    const headerOptions = {
      'Authorization': sessionStorage.getItem('token'),
      'Accept': 'application/json'
    };

    const req = new HttpRequest('POST', environment.baseUrl + '/api/v2/admin/file', formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders(headerOptions)
    });

    return this.http.request(req);


    // return this.http.post(this.apiBaseUrl + '/api/v2/admin/file', data, {headers: new HttpHeaders(headerOptions)});
  }


}
