import { Component, Input } from '@angular/core';
import { SharedTitleItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-title',
  templateUrl: 'title.component.html',
})
export class EditorSharedTitleComponent {
  @Input() title: SharedTitleItemComponent;

}
