import { Component, Input, OnInit, AfterViewInit, Output,  EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

import { VimeoService } from 'src/app/@core/services/vimeo.service';
import { SanitizerHelper } from 'src/app/@core/helpers/sanitizer.helper';

@Component({
  selector: 'app-editor-component-challenge',
  templateUrl: 'challenge.component.html'
})
export class EditorChallengeComponent implements OnInit, AfterViewInit {
    @Input() item: any;
    @Output() public sendEvent = new EventEmitter<any>();

    alertShow: boolean;
    btnBlocked: boolean;

    constructor(
        private sanitizer: DomSanitizer,
        private vimeoService: VimeoService
    ) {
      this.alertShow = false;
      this.btnBlocked = true;
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
      for (const i of this.item.medias) {
        i.viewed = false;
      }

      if (this.item.medias.length > 0) {
        this.setFeatured(this.item.medias[0]);
      }
    }

    setFeatured(media: any) {
      for (const i of this.item.medias) {
        i.featured = false;
      }
      this.videoChanged(media);
      this.checkAllViewed();

      media.featured = true;
      media.viewed = true;
    }

    checkAllViewed() {
      const viewedCounter = this.item.medias.filter(item => {
        return item.viewed === true;
      });

      if (viewedCounter.length === this.item.medias.length) {
        if (!this.alertShow) {
          Swal.fire('Parabéns', 'Você já pode passar para próxima etapa!', 'success');
          this.alertShow = true;
        }
        this.btnBlocked = false;
      }
    }

    videoChanged(data: any) {
      const Sanitizer = new SanitizerHelper(this.sanitizer);
      if (data.type === 'youtube') {
        const videoID = Sanitizer.getYoutubeID(data.url);
        data.thumb = 'https://img.youtube.com/vi/' + Sanitizer.getYoutubeID(data.url) + '/hqdefault.jpg';
        data.link = Sanitizer.sanitizer(data.url, 'youtube');
      }
      if (data.type === 'vimeo') {
        data.thumb = 'https://i.vimeocdn.com/video/default_295x166.webp';
        this.vimeoService.getThumb(data.url).then(res => {
          data.thumb = res;
        });
        data.link = Sanitizer.sanitizer(data.url, 'vimeo');
      }
    }

    sanitize(url: string) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
