import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PdfItemComponent } from 'src/app/@core/models/item-component';

declare var $: any;

@Component({
  selector: 'app-editor-component-pdf-config',
  templateUrl: 'pdf-config.component.html'
})
export class EditorPdfConfigComponent implements OnInit {
  @Input() item: PdfItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();
  pdfLoading: boolean;

  constructor(
  ) {
  }

  ngOnInit() {
    if (this.item.url) {
      this.item.url = this.item.url.replace('/archives/', '/archives%2');
    }
  }

  setAlign(attr: string, align: string) {
    this.item[attr] = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  pdfFirebaseUploaded(file: any) {
    this.item.url = file.path;
    this.embedUpdate();
  }

  embedUpdate() {
    const replace = '<embed id="embed-pdf" src="' + this.item.url + '" width="100%" height="500" type="application/pdf" />';
    $('#embed-pdf').replaceWith(replace);
  }

}
