import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-image-config',
  templateUrl: 'image-config.component.html'
})
export class EditorImageConfigComponent {
  @Input() item: ImageItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: {path: string}) {
    this.item.url = $event.path;
  }

}
