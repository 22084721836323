import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Trail } from '../@core/models/trail';
import { TrailService } from '../@core/services/trail.service';
import { ClientService } from '../@core/services/client.service';
import { TrailStage } from '../@core/models/trail-stage';
import { TrailSubStage } from '../@core/models/trail-substage';
import { TrailBaseService } from '../@core/services/trail-base.service';
import { CertificateConfigService } from '../@core/services/certificate/config.service';
import {
    VideoItemComponent,
    ItemComponent,
    TextItemComponent,
    ImageItemComponent,
    PdfItemComponent,
    ExerciseComponent,
    MultiChoiceComponent,
    IntroComponent,
    ConclusionComponent,
    ChallengeComponent,
    TableComponent,
    MultipleQuestionComponent,
    CorrectIncorrectComponent,
    UploadComponent,
    StarPointComponent,
    DownloadComponent,
    RatingComponent,
    ForumComponent,
    AvaliationItemComponent,
    FormStepComponent,
    ContractComponent,
    ResearchComponent,
    FeedbackItemComponent,
    AudioItemComponent,
    RandomQuestionsItemComponent,
    PageItemComponent
} from '../@core/models/item-component';
import Swal from 'sweetalert2';
import { TrailLayoutService } from '../@core/services/trail-layout';
import { TranslateSharedModule } from '../translate/translate.module';
import { EditorPageComponent } from '../components/page/page.component';
import { TouchSequence } from 'selenium-webdriver';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
    @ViewChild('colLeftComponent', { static: false }) colLeftComponent: any;

    loading: boolean;
    componentLoading: boolean;

    id: number;
    trail: Trail;
    contentId: number;

    itemSelected: ItemComponent;
    trailStageSelected: TrailStage;
    trailSubStageSelected: TrailSubStage;

    componentArray: Array<ItemComponent>;
    templateArray: any;

    customData: { primaryColor: string, secondaryColor: string, greyColor: string } | any;

    certificate: any;
    model: any;

    format: string;
    status: string;

    token: string;

    constructor(
        public activatedRoute: ActivatedRoute,
        public trailService: TrailService,
        public trailBaseService: TrailBaseService,
        public trailLayoutService: TrailLayoutService,
        public certificateConfigService: CertificateConfigService,
        public clientService: ClientService,
        public translateService: TranslateSharedModule
    ) {
        this.loading = true;

        this.customData = {
            primaryColor: '#333333',
            secondaryColor: '#333333',
            greyColor: '#AAAAAA'
        };

        this.componentArray = [];

        const token = sessionStorage.getItem('token');
        this.token = token.split(' ')[1];
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((param: Params) => {
            this.id = +param.id;

            this.getTemplates();
        });
    }

    getTemplates() {
        this.trailLayoutService.getResources().subscribe((res: any) => {
            this.templateArray = res.data;

            console.log('HomeComponent: getTemplates: this.templateArray', this.templateArray);

            this.getTrail();
            this.getCertificateConfig();
            this.getCustomization();
        });
    }


    getCustomization() {
        this.clientService.getColor().subscribe(res => {
            console.log('HomeComponent: getCustomization: res', res);
            if (res[0].config) {
                this.customData = res[0].config;
            } else {
                this.customData = {
                    primaryColor: '#333333',
                    secondaryColor: '#333333',
                    greyColor: '#AAAAAA'
                };
            }
        });
    }

    private getTrail(): void {
        this.trailService.findBy(this.id).then(res => {
            console.log('HomeComponent: getTrail: res', res);
            this.trail = res.data;
            if ([2, 3, 4, 9].indexOf(this.trail.type) > -1) {
                this.verifyModules();
            } else {
                this.loading = false;
            }
        });
    }

    verifyModules() {
        console.log('HomeComponent: verifyModules');
        this.trailBaseService.fetchStages(this.id).subscribe((res: any) => {
            const lenght = res.data.length;
            if (lenght === 0) {
                this.createModule();
            } else {
                this.loading = false;
            }
        });
    }

    createModule() {
        this.trailBaseService.insertStage(
            this.id,
            this.trail.titulo,
            this.trail.sinopse,
            '',
            this.trail.arquivo
        ).subscribe(res => {
            // this.colLeftComponent.getStages();
            this.loading = false;
        });
    }

    insertItem(event: string): void {
        console.log('[newComponent] event ->', event);
        if (!this.trailSubStageSelected) {
            return;
        }

        let newComponent: any;

        const template = this.templateArray.find((item: any) => item.type === event);

        newComponent = this.getDefault(event);
        console.log('[newComponent] ->', newComponent);

        if (template) {
            // newComponent = { ...template.value };
            newComponent.setData(template.value);
        }

        this.componentArray.push(newComponent);

        this.selectItem(this.componentArray[this.componentArray.length - 1]);

        console.log("[newComponent] ->",newComponent);

        this.save();
    }

    getDefault(event: string): ItemComponent {
        let newComponent: any;

        const componentClass = {
            avaliation: AvaliationItemComponent,
            'random-questions': RandomQuestionsItemComponent,
            video: VideoItemComponent,
            text: TextItemComponent,
            image: ImageItemComponent,
            pdf: PdfItemComponent,
            exercise: ExerciseComponent,
            'multi-choice': MultiChoiceComponent,
            intro: IntroComponent,
            conclusion: ConclusionComponent,
            challenge: ChallengeComponent,
            table: TableComponent,
            'multiple-questions': MultipleQuestionComponent,
            'correct-incorrect': CorrectIncorrectComponent,
            upload: UploadComponent,
            'star-point': StarPointComponent,
            download: DownloadComponent,
            rating: RatingComponent,
            forum: ForumComponent,
            'form-step': FormStepComponent,
            contract: ContractComponent,
            research: ResearchComponent,
            feedback: FeedbackItemComponent,
            audio: AudioItemComponent,
            page: PageItemComponent,
        };

        newComponent = new componentClass[event]();

        return newComponent;
    }

    selectItem(item: any) {
        this.itemSelected = item;

        for (const ca of this.componentArray) {
            ca.active = false;
        }

        this.itemSelected.active = true;
    }

    async delete(item: ItemComponent) {

        Swal.fire({
            title: await this.translateService.showTranslator('YouWantDeleteThisComponent'),
            text: await this.translateService.showTranslator('YouWillNotReverseActionOnceConfirmed'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#6C63FE',
            cancelButtonColor: '#d33',
            confirmButtonText: await this.translateService.showTranslator('Yes'),
            cancelButtonText: await this.translateService.showTranslator('No')
        }).then(
            (result) => {
                if (result.value) {
                    const index = this.componentArray.indexOf(item);

                    this.componentArray.splice(index, 1);

                    if (item === this.itemSelected) {
                        this.itemSelected = null;
                    }

                    this.save();
                }
            },
            (cancel) => { }
        );

    }

    subStageSelected(event: any) {

        if (event.substage == null) {
            this.trailStageSelected = null;
            this.trailSubStageSelected = null;

            return;
        }

        if (event.substage === this.trailSubStageSelected) {
            return;
        }

        this.trailStageSelected = event.stage;
        this.trailSubStageSelected = event.substage;

        this.itemSelected = null;

        this.setSubStageActive(this.trailStageSelected.id, this.trailSubStageSelected.id);

        this.getItemsComponent();
    }

    public getItemsComponent() {
        this.componentArray = [];

        this.componentLoading = true;

        this.trailBaseService.getContent(this.trailSubStageSelected.id)
            .subscribe((res: any) => {

                this.contentId = res.data[0].id;

                this.componentArray = [];

                this.componentLoading = false;

                if (res.data[0].value) {
                    const itensJson = res.data[0].value;

                    for (const item of itensJson) {
                        const componentDefault = this.getDefault(item.type);
                        componentDefault.setData(item);
                        this.componentArray.push(componentDefault);
                    }

                    if (this.componentArray.length > 0) {
                        this.selectItem(this.componentArray[0]);
                    }
                }

            });
    }

    setSubStageActive(stageId: number, subStageId: number) {
        // $('.submodules').removeClass('active');
        // $('#stage-' + stageId + '-' + subStageId).addClass('active');
    }

    save() {
        if (!this.format || this.format === 'components') {
            this.status = 'Salvando...';
            this.trailBaseService.setContent(this.contentId, this.componentArray)
                .subscribe(res => {
                    this.status = 'Ultima vez salvo em ' + new Date(Date.now()).toLocaleString();
                });
        }
        if (this.format === 'certificates') {
            const body = { value: { ...this.certificate, model: this.model } };
            const route = { router: this.id + '/trail' };

            this.certificateConfigService.createResource(body, route)
                .subscribe(res => {
                    console.log('certificateConfigService.createResource: res', res);
                    Swal.fire('Sucesso', 'Certificado salvo com sucesso!', 'success');
                });
        }
    }

    getCertificateConfig() {
        this.certificate = {
            title: { text: 'Certificado', size: 60, color: '#444444', align: 'center' },
            department: { text: 'Departamento Aqui', size: 21, color: '#444444', align: 'center' },
            name: { size: 40, color: '#444444', align: 'center' },
            trail: { size: 21, color: '#444444', align: 'center' },
            logo: '',
            footer: '',
            assign: '',
            model: null
        };

        this.certificateConfigService.getResource(this.id, { router: 'trail' })
            .subscribe((res: any) => {
                console.log('HomeComponent: getCertificateConfig: res', res);
                if ((res.data.certificate) && (res.data.certificate.length > 0)) {
                    this.certificate = res.data.certificate[0].value;
                }
            });
    }

    getModelEvent($event: string): void {
        this.model = $event;
    }

    formatSelected(format: string): void {
        this.format = format;
    }

    trailUpdated() {
        this.getTrail();
    }

    itemChange(event) {
        event = JSON.parse(JSON.stringify(event));
        let changeIndex = this.componentArray.findIndex(i => i == this.itemSelected);
        this.componentArray[changeIndex] = event;
        this.itemSelected = event;

        // console.log('EVENT ITEM ->', event);
        // alert('OK');
    }

    reloadPlayerEvent() {

    }

    viewOnPlatform() {
        let useOn = false;
        let url = '';
        let isHMG = location.hostname.includes('hmgasap');

        if (this.customData) {
            if (this.customData.template && this.customData.template.platform_type == 'b2b') {
                useOn = true;
            }
    
            if (this.customData.url && this.customData.url.length > 0) {
                if (isHMG) {
                    url = this.customData.url.find(u => u.includes('hmgasap'));
                }
                
                if (!isHMG || url == '') {
                    url = this.customData.url.find(u => !u.includes('hmgasap'));
                }
    
                if (!url) {
                    url = this.customData.url[0];
                }
            }

    
            if (url && this.trailStageSelected) {
                url = `https://${url}${ (useOn) ? '/on' : '' }?token=${this.token}&ref=/visualizador/etapa/${ this.trailStageSelected.id }`;
    
                if (this.trailSubStageSelected) {
                    url = `${url}&subs=${ this.trailSubStageSelected.id }`;
                }
                // url += `&token=${this.token}`;
                // console.log("URL", url);
            }
            else {
                url = '';
            }
        }

        return {
            visible: url != '',
            url: url
        }
    }

}
