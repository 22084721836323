import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AudioItemComponent } from 'src/app/@core/models/item-component';
import { LiveService } from 'src/app/@core/services/live.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-editor-component-audio-config',
  templateUrl: 'audio-config.component.html'
})
export class EditorAudioConfigComponent {
  @Input() item: AudioItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadPlayer = new EventEmitter<any>();

  liveSending: boolean;
  livePercent: number;

  constructor(public liveService: LiveService) {

  }

  setItemElemAlign(elem: string, align: string): void {
    this.item[elem] = align;
  }

  getPhoto($event: {path: string}) {
    this.item.url = $event.path;
  }

  getCoverPhoto($event: {path: string}) {
    this.item.cover = $event.path;
  }

  onChangeLive(event) {

    this.liveSending = true;

    this.liveService.uploadAudio(event.target.files[0])
    .subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.livePercent = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {

          // const streamAPI = this.global.loggedUser().link;
          const streamAPI = 'https://asap.twcreativs.stream/api/';

          const url = `${streamAPI}audios/${event.body.uuidVideo}/file`;

          this.item.url = url;
          this.liveSending = false;

          this.reloadPlayer.emit();
        }
      },err => {
        console.log('Error', err);
        this.liveSending = false;
      })
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
