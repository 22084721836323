import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;

import { ForumClientService } from 'src/app/@core/services/forum.client';
import { ForumVoteService } from 'src/app/@core/services/forum-vote.service';
import { ForumResponseService } from 'src/app/@core/services/forum-response.service';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

@Component({
  selector: 'app-editor-component-forum',
  templateUrl: 'forum.component.html'
})
export class ForumComponent implements OnChanges, OnInit {
  @Input() item: any;
  @Input() id: any;

  loading = true;

  form: FormGroup;
  formUpdate: FormGroup;
  response: any;
  forum: any;
  loggedUser: any;

  score = 0;
  displayRatingScore = 0;

  constructor(
    private formBuilder: FormBuilder,
    private forumResponseService: ForumResponseService,
    private forumClientService: ForumClientService,
    private forumVoteService: ForumVoteService,
    private translateService: TranslateSharedModule
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      resposta: ['']
    });
    this.formUpdate = this.formBuilder.group({
      id: [''],
      resposta: ['']
    });
  }

  ngOnInit() {
    this.getForum();
    this.getResponse();
    this.getUserLogged();
    console.log(this.item);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.id = this.item.forum;
      this.getForum();
      this.getResponse();
      this.getUserLogged();
    }
    console.log('CONSOLE ->', this.item);
  }

  getUserLogged(): any {
    const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.loggedUser = loggedUser.data;
  }

  getForum() {
    this.forumClientService.getResource(this.id)
      .subscribe((res: any) => {
        this.forum = res.data[0];
        this.getResponse();
      });
  }

  getResponse() {
    this.forumResponseService.getResource(this.id)
      .subscribe((res: any) => {
        this.response = res.data.filter(item => {
          return item.user_id === this.loggedUser.id;
        });
        this.loading = false;
      });
  }

  save() {
    const form = this.form.value;
    this.forumClientService.createResource(form, {router: this.id})
      .subscribe(res => {
        this.getResponse();
        this.ResetPost();
      })
  }

  ResetPost() {
    this.form.reset();
    $('#answer').collapse('hide');
  }

  async onRemove(forum: any) {
    Swal.fire({
        title: await this.translateService.showTranslator('YouWantDelete')+'?',
        text: await this.translateService.showTranslator('YouWillNotReverseActionOnceConfirmed'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: await this.translateService.showTranslator('Yes'),
        cancelButtonText: await this.translateService.showTranslator('No'),
        icon: 'warning'
    }).then(result => {
      if (result.value) {
        this.forumResponseService.deleteResource(forum.id)
          .subscribe((res: any) => {
            this.getResponse();
          });
      }
    }, cancel => {}
    );
  }

  onClose(forum) {
    this.formUpdate.reset();
    return forum.disable = false;
  }

  onEdit(forum) {
    this.formUpdate.setValue({id: forum.id, resposta: forum.resposta})
    return forum.disable = true;
  }

  updateForm() {
    const form = this.formUpdate.value;
    this.forumClientService.updateResource(form)
      .subscribe(res => {
        this.getResponse();
        this.ResetPost();
      })
  }

  vote(item, number) {
    const form = { pontos: number }
    this.forumVoteService.createResource(form, {router: item.id})
    .subscribe(res => {
      this.getResponse();
    })
  }

  getHidden(item) {
    return item.hidden = !item.hidden;
  }



}
