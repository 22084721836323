import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { TrailBaseService } from 'src/app/@core/services/trail-base.service';
import { TrailService } from 'src/app/@core/services/trail.service';

registerLocaleData(localeBr, 'pt');

@Component({
  selector: 'app-editor-certificado-modelo',
  templateUrl: './default.component.html'
})

export class CertificadoModeloComponent implements OnInit {

  @Input() certificate: any;
  @Input() model: any;

  trailStageArray: any;
  stages: string;

  trail: any;
  loggedUser: any;
  id: number;

  today: any;

  constructor(
    private route: ActivatedRoute,
    private trailService: TrailService,
    private oldTrailService: TrailBaseService
  ) {
    this.today = Date.now();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.getTrail();
      this.getLoggedUser();
      this.getStages();
    });
  }

  getLoggedUser() {
    const sessionLoggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.loggedUser = sessionLoggedUser.data;
  }

  getTrail(): void {
    this.trailService.findBy(this.id)
      .then(res => {
        this.trail = res.data;
      });
  }

  getStages(): void {
    this.oldTrailService.fetchStages(this.id)
    .subscribe(res => {

      this.trailStageArray = [];

      const dataLength = res.data.length;

      this.stages = '';

      for (let i = 0; i < dataLength; i++) {
        console.log(i, res.data[i].nome);
        if (i === 0) {
          this.stages += res.data[i].nome;
        } else if (dataLength < i + 1) {
          this.stages += ', ' + res.data[i].nome;
        } else {
          this.stages += ' e ' + res.data[i].nome;
        }
      }
    });
  }

  compare(a, b) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

}
