import { Component, Input } from '@angular/core';
import { SharedButtonItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-button-config',
  templateUrl: 'button-config.component.html'
})
export class EditorSharedButtonConfigComponent {

  @Input() button: SharedButtonItemComponent;
  @Input() customData: any;
  @Input() title: string;
  @Input() colapseId: string = 'colapseButton';

}
