import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailEditorComponent } from 'angular-email-editor';

@Component({
  selector: 'app-editor-component-page',
  templateUrl: 'page.component.html',
  styleUrls: [ './page.component.scss' ]
})
export class EditorPageComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  
  @Input() item: any;
  
  @ViewChild(EmailEditorComponent, {static: false}) editor: EmailEditorComponent;


  constructor(
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {

  }
  
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  public get base64() {
    if (this.item && this.item.content) {
      const content = btoa(unescape(encodeURIComponent(this.item.content.html)));
      return this.sanitizer.bypassSecurityTrustResourceUrl(`data:text/html;base64,${content}`);
    }
    return null;
  }



}
