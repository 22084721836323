import { Component, Input, OnInit } from '@angular/core';
import { AudioItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-audio',
  templateUrl: 'audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class EditorAudioComponent implements OnInit {
  @Input() item: AudioItemComponent;

  ngOnInit() {
  }

}
