import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Guid } from "guid-typescript";
import { TranslateSharedModule } from 'src/app/translate/translate.module';
import { TrailBaseService } from 'src/app/@core/services/trail-base.service';

@Component({
  selector: 'app-editor-component-random-questions-config',
  templateUrl: 'random-question-config.component.html',
  providers: []
})
export class EditorRandomQuestionConfigComponent {
  @Input() item: any = {};
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  @ViewChild('file', { static: false }) file;

  public importing = false;

  constructor(
    private service: TrailBaseService,
    private translateService: TranslateSharedModule
  ) {
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  async createNew() {
    this.item.questions.push({
      id: Guid.raw(),
      question: await this.translateService.showTranslator('QuestionStatement') + '...',
      type: 'objetiva',
      options: [],
      lines: 1,
      collumns: []
    });
  }

  async insertOption(questionIndex: number) {
    this.item.questions[questionIndex].options.push({
      id: Guid.raw(),
      text: await this.translateService.showTranslator('Option'),
      correct: false
    });
  }

  async insertCollumn(questionIndex: number) {
    this.item.questions[questionIndex].collumns.push({
      text: await this.translateService.showTranslator('Column'),
    });
  }

  removeQuestion(questionIndex: number) {
    this.item.questions.splice(questionIndex, 1);
  }

  removeCollumn(questionIndex: number, collumnIndex: number): void {
    this.item.questions[questionIndex].collumns.splice(collumnIndex, 1);
  }

  removeOption(questionIndex: number, option: number): void {
    this.item.questions[questionIndex].options.splice(option, 1);
  }

  onSelectionChange(question: any) {
    console.log('onSelectionChange: question', question);

  }

  async verify(question) {
    if (question.correct) {
      Swal.fire(await this.translateService.showTranslator('YouGot'), question.feedback, 'success');
    } else {
      Swal.fire('Ops...', question.feedback, 'error');
    }
  }

  import() {
    this.file.nativeElement.click();
  }


  fileChange(event) {
    const fileList: FileList = event.target.files;
    this.importing = true;

    if (fileList.length > 0) {
      const file: File = fileList[0];

      this.service.importQuestions(file).subscribe(
        (response: any) => {
          this.file.nativeElement.value = '';
          if (response.success) {
            response.data.forEach(q => {
              this.item.questions.push(q);
            });
          }
          this.importing = false;
        },
        (error) => {
          this.file.nativeElement.value = '';
          this.importing = false;
        }
      );
    }
  }

}
