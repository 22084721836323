import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MaterialDesignModule } from '../../../material.module';

import { TrailService } from 'src/app/@core/services/trail.service';

@Component({
  selector: 'app-top-menu-dialog',
  templateUrl: 'dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class TopMenuDialogComponent {
  isPosting: boolean;
  fixedDual: boolean;
  instructorImage;

  trailItemForm: FormGroup;
  trail: any;
  trailConfig: any;

  constructor(
    public trailService: TrailService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TopMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGenerate();

    this.fixedDual = false;

    this.trail = data.trail;
    this.populateForm(data.trail);
    this.trailConfig = data.config;
  }

  populateForm(data: any): void {
    this.trailItemForm.patchValue({
      title: data.titulo,
      description: data.descricao,
      nivel: data.nivel,
      img: data.arquivo,
      selo: data.selo,
      percentage_certificate: data.percentage_certificate,
      time: data.time,
      course_required: data.course_required
    });

    console.log('populateForm: data', data);

    if (data.value) {
      if (typeof data.value === 'string') {
        data.value = JSON.parse(data.value);
      }

      this.trailItemForm.get('value').patchValue({
        subtitle: data.value.subtitle,
        target: data.value.target,
        instructor: data.value.instructor,
        certification_duration: data.value.certification_duration,
        instructor_image: data.value.instructor_image,
        percentage_certificate: data.value.percentage_certificate,
        time: data.value.time,
        course_required: data.value.course_required
      });

      data.value.learn.map((i: string) => {
        this.addValue('learn', i);
      });
      data.value.requirements.map((i: string) => {
        this.addValue('requirements', i);
      });
      data.value.includes.map((i: string) => {
        this.addValue('includes', i);
      });

      this.instructorImage = data.value.instructor_image;
    }
  }

  addValue(field: string, value: string): void {
    const array = this.trailItemForm.get('value').get(field) as FormArray;
    array.push(this.formBuilder.control(value));
  }

  removeValue(field: string, index: number) {
    const array = this.trailItemForm.get('value').get(field) as FormArray;
    array.removeAt(index);
  }


  formGenerate() {
    this.trailItemForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: '',
      nivel: [''],
      img: [''],
      selo: '',
      percentage_certificate: [''],
      course_required: [false],
      time: [''],
      value: this.formBuilder.group({
        subtitle: '',
        target: '',
        instructor: '',
        instructor_image: '',
        certification_duration: '',
        learn: this.formBuilder.array([]),
        requirements: this.formBuilder.array([]),
        includes: this.formBuilder.array([])
      })
    });
  }

  getValue(control: string) {
    const valueArray = this.trailItemForm.get('value').get(control) as FormArray;
    return valueArray.controls;
  }

  get seal(): string {
    return this.trailItemForm.get('selo').value;
  }

  getSealImage(image: {path: string}): void {
    this.trailItemForm.get('selo').setValue(image.path);
  }

  getInstructorImage(image: {path: string}): void {
    this.trailItemForm.get('value.instructor_image').setValue(image.path);
    this.instructorImage = image.path;
  }

  removeSealImg(): void {
    this.trailItemForm.get('selo').setValue(null);
  }

  removeInstructorImg(): void {
    this.trailItemForm.get('value.instructor_image').setValue(null);
    this.instructorImage = null;
  }

  getPhoto(event: any) {
    this.trailItemForm.get('img').setValue(event.path);
  }

  postForm() {
    const form = this.trailItemForm;

    if (!form.valid) {
      console.log(form);
      return;
    }

    this.isPosting = true;

    console.log(form.value);

    this.trailService.updateItem(
      this.trail.id,
      this.trail.pasta_id,
      form.value.title,
      form.value.description,
      form.value.img,
      form.value.nivel,
      form.value.selo,
      form.value.value,
      form.value.percentage_certificate,
      form.value.time,
      form.value.course_required
    ).subscribe((res: any) => {
      this.isPosting = false;

      this.formGenerate();

      this.dialogRef.close(true);
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
