import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ActivatedRoute } from '@angular/router';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    TranslateModule
  ],
  providers: [TranslateService]
})
export class TranslateSharedModule {

  constructor(private translate: TranslateService, private router: ActivatedRoute) {
    let timer = setInterval(() => {
      const getLanguage = sessionStorage.getItem('language');
      console.log('GET LANGUAGE ->', getLanguage);
      if (!getLanguage) {
        this.router.queryParams.subscribe(param => {
          if (param.lang != undefined) {
            console.log('Params ->', param.lang);
            sessionStorage.setItem('language', param.lang);
          }
          else {
            sessionStorage.setItem('language', 'pt-br');
          }
        })
      }
      else if (getLanguage) {
        var language = (getLanguage && getLanguage != 'undefined' && getLanguage != '') ? getLanguage : 'pt-br';
        translate.setDefaultLang(language);
        translate.use(language);
        clearInterval(timer);
      }
    }, 40)





  }

  showTranslator(txt) {
    return this.translate.get(txt).toPromise();
  }

}
