import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PipeModule } from './@core/pipes/pipe.module';
import { SortablejsModule } from 'ngx-sortablejs';

import { AsapUploadModule } from 'asap-upload';

import { MaterialDesignModule } from './material.module';

import { AppComponent } from './app.component';
import { StructureModule } from './structure/structure.module';
import { CertificateModule } from './structure/certificate/certificate.module';
import { ComponentsModule } from './components/components.module';
import { HomeComponent } from './home/home.component';
import { RedirectComponent } from './redirect/redirect.component';
import { TranslateSharedModule } from './translate/translate.module';
import { AsapCrudModule } from 'asap-crud';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { EmailEditorModule } from 'angular-email-editor';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AsapCrudModule,
    AsapUploadModule,
    StructureModule,
    PipeModule,
    ComponentsModule,
    MaterialDesignModule,
    CertificateModule,
    TranslateSharedModule,
    SimpleNotificationsModule.forRoot({}),
    SortablejsModule.forRoot({ animation: 150 }),
    RouterModule.forRoot([
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: ':id',
        component: HomeComponent
      }
    ]),
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCnQ7hg9qn8mrS3zSLX-xeXX3wKbuC2GXA',
      authDomain: 'admin-17e4f.firebaseapp.com',
      databaseURL: 'https://admin-17e4f.firebaseio.com',
      projectId: 'admin-17e4f',
      storageBucket: 'admin-17e4f.appspot.com',
      messagingSenderId: '572223664609'
    }),
    AngularFireStorageModule,
    EmailEditorModule
  ],
  exports: [TranslateSharedModule],
  providers: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
