import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({providedIn: 'root'})
export class ClientService {

  constructor(
    public http: HttpClient,
    public storageService: StorageService
  ) {

  }

  getColor(id = null): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/color' + ((id) ? ('/' + id) : ''), this.getHeaders());
  }

  getClientData() {
    const id = this.storageService.get('loggedUser').data.client_id;
    return this.http.get(environment.baseUrl + '/api/v2/admin/asap-client/' + id, this.getHeaders());
  }

  putColor(config: any, id = null): Observable<any> {
    const body = {
        config
    };
    return this.http.put(environment.baseUrl + '/api/v2/admin/color' + ((id) ? ('/' + id) : ''), body, this.getHeaders());
  }

  getHeaders(): {headers: HttpHeaders} {
    const token = this.storageService.get('token', false);
    const headerOptions = {
      Authorization: token,
      Accept: 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

}
