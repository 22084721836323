import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { UploadComponent } from 'src/app/@core/models/item-component';

// import filestack from 'filestack-js';

// const client = filestack.init(environment.filestack.key);

@Component({
  selector: 'app-editor-component-upload',
  templateUrl: 'upload.component.html'
})

export class EditorUploadComponent implements OnInit {

  @Input() item: UploadComponent;
  @Output() public event = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.item.file = [];
  }

  onDone() {
    this.event.emit();
  }

  remove(f: any) {
    const index = this.item.file.indexOf(f);
    this.item.file.splice(index, 1);
  }

  showFileManager() {
    console.log('showFileManager');
  }

}
