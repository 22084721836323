import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';
import { Ng2IziToastModule } from 'ng2-izitoast';

import { TopMenuComponent } from './top-menu/top-menu.component';
import { ColLeftComponent } from './col-left/col-left.component';
import { ColRightComponent } from './col-right/col-right.component';
import { FooterComponent } from './footer/footer.component';

import { ComponentsModule } from '../components/components.module';
import { MaterialDesignModule } from '../material.module';
import { TopMenuDialogComponent } from './top-menu/dialog/dialog.component';
import { StageDialogComponent } from './col-left/stage-dialog/stage-dialog.component';
import { SubStageDialogComponent } from './col-left/sub-stage-dialog/sub-stage-dialog.component';
import { FeaturedDialogComponent } from './col-left/featured-dialog/featured-dialog.component';
import { CertificateModule } from './certificate/certificate.module';
import { ModalNotificationEnableComponent } from './top-menu/modal-notification-enable/modal-notification-enable.component';
import { TranslateSharedModule } from '../translate/translate.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    CertificateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SortablejsModule,
    MaterialDesignModule,
    Ng2IziToastModule,
    TranslateSharedModule
  ],
  declarations: [
    TopMenuComponent,
    ColLeftComponent,
    ColRightComponent,
    FooterComponent,
    TopMenuDialogComponent,
    StageDialogComponent,
    SubStageDialogComponent,
    FeaturedDialogComponent,
    ModalNotificationEnableComponent
  ],
  exports: [
    TopMenuComponent,
    ColLeftComponent,
    ColRightComponent,
    FooterComponent
  ],
  entryComponents: [
    TopMenuDialogComponent,
    StageDialogComponent,
    SubStageDialogComponent,
    FeaturedDialogComponent,
    ModalNotificationEnableComponent
  ]
})
export class StructureModule { }
