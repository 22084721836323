import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class VimeoService {

  constructor(public http: HttpClient) {

  }

  getThumb(vimeoUrl: string): Promise<string> {
    const url = 'https://vimeo.com/api/oembed.json?url=' + vimeoUrl;
    return new Promise<string>((resolve, reject) => {
      this.http.get(url)
      .subscribe((res: {thumbnail_url_with_play_button: string}) => {
        resolve(res.thumbnail_url_with_play_button);
      });
    });
  }


}
