import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

@Component({
  selector: 'app-editor-component-form-step-config',
  templateUrl: 'form-step-config.component.html'
})
export class EditorFormStepConfigComponent implements OnChanges {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor( private translateService: TranslateSharedModule ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.item) {
      if (this.item.steps) {
        this.item.steps.forEach(step => {
          this.normalizeStep(step)
        });
      }
    }
  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  normalizeStep(step) {
    if (!step.enunciated) step.enunciated = {};
    if (step.inputArray) {
      step.inputArray.forEach(input => {
        if (input.visibility_control_value) {
          if (!Array.isArray(input.visibility_control_value)) {
            input.visibility_control_value = [input.visibility_control_value];
          }
        }
        else {
          input.visibility_control_value = [];
        }
      });
    }
    return step;
  }

  async createNew() {
    this.item.steps.push({
      resume: await this.translateService.showTranslator('EnunciatedGeneralOfTheStage'),
      enunciated: {},
      inputArray: []
    });
  }

  removeStep(index: number) {
    this.item.steps.splice(index, 1);
  }

  async insertInput(index: number) {
    this.item.steps[index].inputArray.push({
      resume: await this.translateService.showTranslator('ControlStatement'),
      type: 'text',
      visibility: "always",
      visibility_control_value: []
    });
  }

  removeInput(iStep: number, iInput: number) {
    this.item.steps[iStep].inputArray.splice(iInput, 1);
  }

  toggle(array, value) {
    if (array) {
      let idx = array.indexOf(value)
      if (idx >= 0) {
        array.splice(idx,1);
      }
      else {
        array.push(value);
      }
    }
  }

}
