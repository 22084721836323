import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-component-contract',
  templateUrl: 'contract.component.html'
})
export class EditorContractComponent {
  @Input() item: any = {};

  constructor(){
    console.log('[EditorContractComponent] item ->', this.item);
  }
}
