import { Component, Input } from '@angular/core';
import { VideoItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-video',
  templateUrl: 'video.component.html'
})
export class EditorVideoComponent {
  @Input() item: VideoItemComponent;

}
