import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Global } from '../lib/global';
import { TrailStage } from '../models/trail-stage';

@Injectable({providedIn: 'root'})
export class TrailBaseService {

    constructor(
        public global: Global,
        public http: HttpClient
    ) {
    }

    public fetchAll(type = 'trail', role = '0', clientID = null): Observable<any> {
        const header = { headers: this.getHeaders() };
        let replaceClient = '';
        if (clientID) {
            replaceClient = '&useClient=' + clientID;
        }
        return this.http.get(environment.apiUrl + '/api/v2/admin/' + type + '/folder?role=' + role + replaceClient, header);
    }

    public insert(name: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/folder/post';
        const body = {
            nome_pasta: name
        };
        const header = { headers: this.getHeaders() };

        return this.http.post(url, body, header);
    }

    public update(id: number, name: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/folder/' + id + '/update';
        const body = {
            nome_pasta: name
        };
        const header = { headers: this.getHeaders() };

        return this.http.put(url, body, header);
    }

    public deleteFolder(id: number) {
        const url = environment.apiUrl + '/api/v2/admin/trail/folder/' + id + '/delete';
        const header = { headers: this.getHeaders() };

        return this.http.delete(url, header);
    }

    public findFolder(id: number): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/admin/trail/folder/' + id;
        return this.http.get(url, header);
    }

    public folderShared(): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/admin/shared/folder/user';
        return this.http.get(url, header);
    }

    public folderShop(): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/admin/shop/curso';
        return this.http.get(url, header);
    }

    public fetchAllItems(): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/admin/trail';
        return this.http.get(url, header);
    }

    public fetchItems(id: number): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/admin/trail/folder/' + id + '/items';
        return this.http.get(url, header);
    }

    public allCourses(): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/loja/cursos';
        return this.http.get(url, header);
    }

    public insertItems(
      trailId: number, title: string, description: string,
      image: string, nivel: string, group_id: number = null
    ): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/item/post';
        const body = {
            pasta_id: trailId,
            titulo: title,
            descricao: description,
            arquivo: image,
            group_id,
            nivel
        };
        const header = { headers: this.getHeaders() };

        return this.http.post(url, body, header);
    }


    public updateItem(id: number, trailId: number, title: string, description: string, image: string, nivel: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/item/' + id + '/update';
        const body = {
            pasta_id: trailId,
            titulo: title,
            descricao: description,
            arquivo: image,
            nivel
        };
        const header = { headers: this.getHeaders() };

        return this.http.put(url, body, header);
    }

    public deleteItem(id: number) {
        const url = environment.apiUrl + '/api/v2/admin/trail/item/' + id + '/delete';
        const header = { headers: this.getHeaders() };

        return this.http.delete(url, header);
    }


    public fetchStages(id: number): Observable<any> {
        const header = { headers: this.getHeaders() };
        const url = environment.apiUrl + '/api/v2/admin/trail/stage/' + id;
        return this.http.get(url, header);
    }

    public insertStage(itemId: number, title: string, sinopse: string, time: string, image: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/stage/post';
        const body = {
            item_id: itemId,
            nome: title,
            sinopse,
            time,
            image
        };
        const header = { headers: this.getHeaders() };

        return this.http.post(url, body, header);
    }

    public updateStage(itemId: number, id: number, title: string, sinopse: string, time: string, image: string) {
        const url = environment.apiUrl + '/api/v2/admin/trail/stage/' + id + '/update';
        const body = {
            item_id: itemId,
            nome: title,
            sinopse,
            time,
            image
        };
        const header = { headers: this.getHeaders() };

        return this.http.put(url, body, header);
    }

    public stagePut(id: number, data: TrailStage) {

        const body = {
            nome: data.title,
            sinopse: data.sinopse,
            time: data.time,
            image: data.image,
            rotulo: data.rotulo,
            text_rotulo: data.text_rotulo,
            item_id: id
        };

        const url = environment.apiUrl + '/api/v2/admin/trail/stage/' + data.id + '/update';
        const header = { headers: this.getHeaders() };
        return this.http.put(url, body, header);
    }

    public deleteStage(id: number) {
        const url = environment.apiUrl + '/api/v2/admin/trail/stage/' + id + '/delete';
        const header = { headers: this.getHeaders() };

        return this.http.delete(url, header);
    }

    public activateStage(id: number) {
        const url = environment.apiUrl + '/api/v2/admin/trail/stage/' + id + '/enable';
        const header = { headers: this.getHeaders() };

        return this.http.get(url, header);
    }

    public insertSubStage(stageId: number, title: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/sub-stage/store';
        const body = {
            etapa_id: stageId,
            nome: title
        };
        const header = { headers: this.getHeaders() };

        return this.http.post(url, body, header);
    }

    public updateSubStage(stageId: number, id: number, title: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/admin/trail/sub-stage/' + id + '/update';
        const body = {
            etapa_id: stageId,
            nome: title
        };
        const header = { headers: this.getHeaders() };

        return this.http.put(url, body, header);
    }

    public deleteSubStage(id: number) {
        const url = environment.apiUrl + '/api/v2/admin/trail/sub-stage/' + id + '/delete';
        const header = { headers: this.getHeaders() };

        return this.http.delete(url, header);
    }

    public _getContent(id: number) {
      const header = { headers: this.getHeaders() };
      const url = environment.apiUrl + '/api/v2/admin/trail/content/' + id;
      return this.http.get(url, header);
    }

    public getContent(id: number) {
      const header = { headers: this.getHeaders() };
      const url = environment.apiUrl + '/api/v2/client/trilha/conteudo/' + id;
      return this.http.get(url, header);
    }

    public setContent(contentId: number, content: any) {
        const url = environment.apiUrl + '/api/v2/admin/trail/content/' + contentId + '/update';
        const header = { headers: this.getHeaders() };
        return this.http.put(url, { value: content }, header);
    }

    private getHeaders(extraOptions = {}): HttpHeaders {
        const token = sessionStorage.getItem('token');

        const headerOptions = {
            Authorization: token,
            Accept: 'application/json'
        };

        for (let key in extraOptions) {
            headerOptions[key] = extraOptions[key];
        }

        return new HttpHeaders(headerOptions);
    }

    orderStages(order: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/client/trilha/order/stages';
        const header = { headers: this.getHeaders() };
        return this.http.post(url, { order }, header);
    }

    orderSubStages(order: string): Observable<any> {
        const url = environment.apiUrl + '/api/v2/client/trilha/order/sub-stages';
        const header = { headers: this.getHeaders() };
        return this.http.post(url, { order }, header);
    }

    getTrailByID(id): Observable<any> {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.get(environment.apiUrl + '/api/v2/client/trilha/detalhe/' + id, { headers: header });
    }

    getTrail(): Observable<any> {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.get(environment.apiUrl + '/api/v2/client/trilha', { headers: header });
    }

    getTrailUser(): Observable<any> {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.get(environment.apiUrl + '/api/v2/client/trilha/autenticado', { headers: header });
    }

    getFavorite(): Observable<any> {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.get(environment.apiUrl + '/api/v2/client/favorito', { headers: header });
    }

    postFavorite(id: any): Observable<any> {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.post(environment.apiUrl + '/api/v2/client/favorito', { trilha_id: id }, { headers: header });
    }

    getGroupFolder(id: any, folder: any): Observable<any> {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.get(environment.apiUrl + '/api/v2/admin/trail/filter/' + id + '/' + folder, { headers: header });
    }

    hasTrail(trail_id) {
        const token = sessionStorage.getItem('token');
        const headerOptions = {
            Authorization: token,
            Accept: 'application/json'
        };
        const header = new HttpHeaders(headerOptions);
        return this.http.get(environment.apiUrl + '/api/v2/admin/asap-client/has-trail/' + trail_id, { headers: header });
    }

    doDuplicate(trail, type, role = '0') {
        return new Promise((resolve) => {
            if (this.global.hasCompanies()) {
                this.hasTrail(trail).subscribe((response: any) => {
                    const companies = this.global.companies.map((c) => {
                        c.checked = response.data.find(i => c.id == i.client_id);
                        return c;
                    });
                    this.global.searchData({
                        title: 'Selecione a Conta Destino',
                        searchList: companies,
                        descriptionField: 'name',
                    }).subscribe((client) => {
                        console.log('Client', client);
                        this.fetchAll(type, role, client.id).subscribe((response) => {
                            const folders = response.data;
                            this.global.searchData({
                                title: 'Selecionar Pasta',
                                searchList: folders,
                                descriptionField: 'nome_pasta',
                            }).subscribe((folder) => {
                                console.log('Folder', folder, trail);
                                this.duplicate(trail, folder.id, client.id).subscribe((response) => {
                                    if (resolve) { resolve(response); }
                                });
                            });
                        });
                    });
                });
            } else {
                this.fetchAll(type, role).subscribe((response) => {
                    const folders = response.data;
                    this.global.searchData({
                        title: 'Selecionar Pasta',
                        searchList: folders,
                        descriptionField: 'nome_pasta',
                    }).subscribe((folder) => {
                        console.log('Folder', folder, trail);
                        this.duplicate(trail, folder.id).subscribe((response) => {
                            if (resolve) { resolve(response); }
                        });
                    });
                });
            }
        });
    }

    doMove(trail, type, role = '0') {
        return new Promise((resolve) => {
            this.fetchAll(type, role).subscribe((response) => {
                const folders = response.data;
                this.global.searchData({
                    title: 'Selecionar Pasta',
                    searchList: folders,
                    descriptionField: 'nome_pasta',
                }).subscribe((folder) => {
                    this.move(trail, folder.id).subscribe((response) => {
                        if (resolve) { resolve(response); }
                    });
                });
            });
        });
    }

    duplicate(trail_id, folder_id, client_id = null) {
        let url = environment.apiUrl + '/api/v2/client/duplicate/trail/' + trail_id + '/' + folder_id;
        if (client_id) {
            url = url + '/' + client_id;
        }
        const header = { headers: this.getHeaders() };
        return this.http.get(url, header);
    }

    move(trail_id, folder_id) {
        const url = environment.apiUrl + '/api/v2/client/move/trail/' + trail_id + '/' + folder_id;
        const header = { headers: this.getHeaders() };
        return this.http.get(url, header);
    }

    highlight(trail_id) {
        const url = environment.apiUrl + '/api/v2/admin/trail/folder/destaque/' + trail_id;
        const header = { headers: this.getHeaders() };
        return this.http.post(url, {}, header);
    }

    importQuestions(file) {
        const url = environment.apiUrl + '/api/v2/admin/trail/content/import';
        const header = { headers: this.getHeaders() };
        
        let formData:FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(url, formData, header);
    }

}
