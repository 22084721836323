import { Component } from '@angular/core';

@Component({
  selector: 'app-editor-component-yes-no',
  templateUrl: 'yes-no.component.html'
})

export class EditorYesNoComponent {

  constructor() {
  }

}
