import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SelectDialogComponent } from 'src/app/shared/select-dialog/select-dialog.component';

declare const $: any;

@Injectable({providedIn: 'root'})
export class Global {

    public loading;
    public companies;

    constructor(
        public dialog: MatDialog,
    ) {

    }

    public loggedUser() {
        let user: any = sessionStorage.getItem('loggedUser');
        if (user) {
            user = JSON.parse(user).data;
            return user;
        }
        return null;
    }

    public searchData(searchParams) {
        const dialog = this.dialog.open(SelectDialogComponent, {
            width: '550px',
            data: searchParams
        });
        return dialog.afterClosed();
    }

    public showNotification(message: string, type = 'success', icon = 'notifications', position = 'top-right', delay= 3000) {
        const pos = position.split('-');

        $.notify({
            icon,
            message
        }, {
            type,
            timer: delay,
            placement: {
                from: pos[0],
                align: pos[1]
            }
        });
    }

    public hasCompanies() {
        if (this.companies) {
            return true;
        } else {
            const companies = sessionStorage.getItem('companies');
            if (companies) {
                this.companies = JSON.parse(companies);
                return true;
            }
            return false;
        }
    }

}
