import { Component, Input, OnInit } from '@angular/core';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor-component-multiple-questions',
  templateUrl: 'multiple-questions.component.html',
})

export class EditorMultipleQuestionsComponent implements OnInit {

  @Input() item: any;
  view: any;

  constructor(
    private translateService: TranslateSharedModule
  ) {

  }

  ngOnInit() {
      for (const i of this.item.questions) {
        i.sended = false;
      }
  }

  storeAnswer(question: {correct: boolean}, option: {correct: boolean}) {
      question.correct = option.correct;
  }

  async verify(question: {correct: boolean}) {
    if (question.correct) {
        Swal.fire(await this.translateService.showTranslator('YouGot'), await this.translateService.showTranslator('TheSelectedOptionIsCorrect'), 'success');
    } else {
        Swal.fire('Ops...', await this.translateService.showTranslator('YouHaveNotSelectedCorrectOption'), 'error');
    }
  }

}
