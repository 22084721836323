import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-editor-component-exercises-config',
  templateUrl: 'exercises-config.component.html'
})
export class EditorExercisesConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
