import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'vimeoEmbed' })
export class VimeoEmbedPipe implements PipeTransform {
  constructor() { }

  transform(url: any): string {
    return 'https://player.vimeo.com/video/' + this.getVimeoID(url);
  }

  getVimeoID(url: string): string {
    if (!url) {
      return '';
    }
    // tslint:disable-next-line:max-line-length
    const regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const match = url.match(regExp);
    return (match && match[3]) ? match[3] : '';
  }

}
