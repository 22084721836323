import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-component-long-text-config',
  templateUrl: 'long-text-config.component.html'
})

export class EditorLongTextConfigComponent {
  @Input() customData: any;
}
