import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { TrailService } from 'src/app/@core/services/trail.service';

@Component({
  selector: 'app-modal-notification-enable',
  templateUrl: './modal-notification-enable.component.html',
  styleUrls: ['./modal-notification-enable.component.scss'],
  providers: [Ng2IzitoastService]
})
export class ModalNotificationEnableComponent implements OnInit {

  public entity: any = {};
  public loading = false;
  constructor(
    public dialogRef: MatDialogRef<ModalNotificationEnableComponent>,
    public trailService: TrailService,
    public iziToast: Ng2IzitoastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log('Data ->', this.data);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.loading = true;
    this.trailService.sendTrailNotification(this.entity, this.data.id).subscribe(response => {
      if (response) {
        if (response.result == true) {
          this.iziToast.show({
            title: response.message
          })
        }
        else {
          this.iziToast.show({
            title: response.message
          })
        }
      }

      this.dialogRef.close(true);
      this.loading = false;
    })
  }

}
