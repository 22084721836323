import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SharedTitleItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-shared-title-config',
  templateUrl: 'title-config.component.html'
})
export class EditorSharedTitleConfigComponent {
  @Input() title: SharedTitleItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor() {
  }

  setItemAlign(align: string): void {
    this.title.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
