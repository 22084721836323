import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {LibraryHelper} from '../helpers/library.helper';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class Trail {
  public id: string;
  public name: string;
  public date: string;
  public titulo?: string;
  public descricao?: string;
  public arquivo?: string;
  public pasta_id?: number;
  public role: string;
  public tag: string;
  public sinopse?: string;
  public permission?: any[];
  public groups;
  public extra;
  public type: number;

  constructor(private http: HttpClient) {

  }

  static role(type = null):any {
    if (type) {
      return type;
    }
    else {
      if (window.location.pathname.indexOf("trilhas-livres") >= 0) {
        return 6;
      }
      return 0;
    }
  }

  populate(data: any): Trail {
    const trail = new Trail(this.http);
    trail.id = data.id;
    trail.name = data.name;
    trail.date = data.date;
    trail.role = data.role;
    trail.tag = data.tag;
    trail.sinopse = data.sinopse;
    trail.permission = data.permission;
    trail.extra = data.extra;
    if (data.groups) trail.groups = data.groups;
    return trail;
  }

  getPermission(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/video/permission', LibraryHelper.getHeaders());
  }

  findAll(type = null): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder?role=' + Trail.role(type), LibraryHelper.getHeaders());
  }

  findNivelAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trailnivel/folder', LibraryHelper.getHeaders());
  }

  findDevelopAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/desenvolvimento/folder', LibraryHelper.getHeaders());
  }

  findGroupAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trailgroup/folder', LibraryHelper.getHeaders());
  }

  findArticleAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trailarticle/folder', LibraryHelper.getHeaders());
  }

  findMiniAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trailmini/folder', LibraryHelper.getHeaders());
  }

  findAllClient(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha', LibraryHelper.getHeaders());
  }

  findOneBy(data: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/item/' + data.id + '/edit', LibraryHelper.getHeaders());
  }

  findBy(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/item/' + id + '/edit', LibraryHelper.getHeaders());
  }

  filterBy(data: any[]): Observable<any> {
    return this.http.get(environment.baseUrl
      + '/api/v2/admin/trail/config/filter/group?nome=&cpf=&sexo=&localidade=&uf=&unidades=&cargos=&unidade='
      + data[1] + '&cargo=' + data[0], LibraryHelper.getHeaders());
  }

  update(trail: Trail): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/v2/admin/trail/item/' + trail.id + '/update', trail, LibraryHelper.getHeaders());
  }

  persist(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role,
      permission: trail.permission,
      groups: trail.groups,
      sinopse: trail.sinopse,
      extra: trail.extra
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trail/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/trail/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  persist_nivel(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role,
      sinopse: trail.sinopse,
      permission: trail.permission,
      groups: trail.groups
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trail/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/trailnivel/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  persist_article(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role,
      sinopse: trail.sinopse,
      permission: trail.permission,
      groups: trail.groups
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trailnivel/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/trailarticle/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  persist_mini(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role,
      sinopse: trail.sinopse,
      permission: trail.permission,
      groups: trail.groups
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trail/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/trailmini/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  persist_develop(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role,
      sinopse: trail.sinopse,
      permission: trail.permission,
      groups: trail.groups
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trailnivel/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/desenvolvimento/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  persist_group(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role,
      sinopse: trail.sinopse,
      permission: trail.permission,
      groups: trail.groups
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trailnivel/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/trailgroup/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  saveData(trail: Trail): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/item/post', trail, LibraryHelper.getHeaders());
  }

  delete(id: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/admin/trail/folder/' + id + '/delete';
    return this.http.delete(url, LibraryHelper.getHeaders());
  }

  setStatus(data: {status: number}, id: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/client/trilha/published/' + id;
    return this.http.post(url, data, LibraryHelper.getHeaders());
  }

  sendTrailNotification(data:any, id: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/client/trilha/notificate/' + id;
    return this.http.post(url, data, LibraryHelper.getHeaders());
  }

  setClass(id: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/admin/trail/item/enables/class/' + id;
    return this.http.post(url, {}, LibraryHelper.getHeaders());
  }

}
