import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { trigger, style, transition, animate, query, stagger, animateChild } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

import { ConclusionComponent } from 'src/app/@core/models/item-component';
import { TrailService } from 'src/app/@core/services/trail.service';

@Component({
  selector: 'app-editor-component-conclusion',
  templateUrl: 'conclusion.component.html',
  styleUrls: ['./conclusion.component.scss'],
  animations: [
    trigger('icon', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', display: 'none', opacity: 0 }),
        animate('500ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1.3)', display: 'block', opacity: 1 })),
        animate('300ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)' }))

      ])
    ]),
    trigger('icons', [
      transition(':enter', [
        query('@icon', stagger(500, animateChild()))
      ])
    ])
  ]
})
export class EditorConclusionComponent implements OnInit {
  @Input() item: ConclusionComponent;
  @Input() customData: any;
  @Output() public event = new EventEmitter<any>();
  loggedUser: any;

  id: number;
  badge: string;

  constructor(
    private route: ActivatedRoute,
    private trailService: TrailService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.id = +params.id;
      this.getStageData();
    });
  }

  getStageData(): void {
    this.trailService.findBy(this.id)
      .then(res => {
        this.badge = res.data.arquivo;
      });
  }

  onDone() {
    this.event.emit();
  }
}
