import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VideoItemComponent } from 'src/app/@core/models/item-component';
import { FileService } from 'src/app/@core/services/file.service';
import { FirebaseApp } from '@angular/fire';
import { HttpResponse } from '@angular/common/http';
import { TranslateSharedModule } from 'src/app/translate/translate.module';

@Component({
  selector: 'app-editor-component-text-config',
  templateUrl: 'text-config.component.html',
  styleUrls: ['../text.component.scss']

})
export class EditorTextConfigComponent {
  @Input() item: VideoItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  public editorOptions: Object = {
    // tslint:disable-next-line: max-line-length
    plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern help',
    // tslint:disable-next-line: max-line-length
    toolbar: 'styleselect | bold italic forecolor | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist | removeformat | fullscreen',
    branding: false,
    height: 500,
    menubar: false,
    statusbar: false,
    image_advtab: true,
    // images_upload_handler: (blobInfo: any, success: any, failure: any) => {
    //   success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
    // },
    // images_upload_url: 'http://localhost/test.php',
    // automatic_uploads: false,
    images_upload_handler: (blobInfo: any, success: any, failure: any) => {
      this.fileService.uploadFile({file: blobInfo.blob()})
        .subscribe((event: any) => {
          console.log(event);
          if (event instanceof HttpResponse) {
            success(event.body.data.url);
          }
        }, err => {
          failure(err);
        });
      console.log(blobInfo);
    },
    setup: async (editor: any) => {
      editor.ui.registry.addButton('saveButton', {
        icon: 'save',
        tooltip: await this.translateService.showTranslator('Save'),
        onAction: async () => {
          alert(await this.translateService.showTranslator('SavedSuccessfully'));
          this.emitSendEvent();
        }
      });
    }
  };

  constructor(
    public fileService: FileService,
    private translateService: TranslateSharedModule
  ) {

  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }
}
