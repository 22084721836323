import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-component-dynamic-input',
  templateUrl: 'dynamic-input.component.html'
})

export class DynamicInputComponent {
  @Input() input: any;
  @Input() customData: any;

  constructor(
  ) { }

}
