import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../lib/global';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LiveService {

  constructor(
      public http: HttpClient,
      public global: Global
  ) {

  }

  uploadVideo(data: any): Observable<HttpEvent<{}>> {

    const streamAPI = this.global.loggedUser().link;

    const formData: FormData = new FormData();
    formData.append('file', data);

    const headerOptions = {
      Authorization: sessionStorage.getItem('token'),
      Accept: 'application/json'
    };

    const req = new HttpRequest('POST', streamAPI + 'videos/upload', formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders(headerOptions)
    });

    return this.http.request(req);
  }

  uploadAudio(file: any): Observable<HttpEvent<{}>> {

    // const streamAPI = this.global.loggedUser().link;
    const streamAPI = 'https://asap.twcreativs.stream/api/';

    const formData: FormData = new FormData();
    formData.append('file', file);

    const headerOptions = {
      Authorization: sessionStorage.getItem('token'),
      Accept: 'application/json'
    };

    const req = new HttpRequest('POST', streamAPI + 'audios/upload', formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders(headerOptions)
    });

    return this.http.request(req);
  }


  // return this.http.request(req).catch(() => {
  //   const newReq = req.clone();
  //   return this.http.request(newReq);
  // });
}
