import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FeedbackItemComponent } from 'src/app/@core/models/item-component';

@Component({
  selector: 'app-editor-component-feedback-config',
  templateUrl: 'feedback-config.component.html'
})
export class EditorFeedbackConfigComponent implements OnInit {
  @Input() item: FeedbackItemComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public itemChange = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit() {

  }

  setItemElemAlign(elem: string, align: string): void {
    this.item[elem] = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
