import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-editor-component-exercises',
  templateUrl: './exercises.component.html'
})
export class EditorExercisesComponent implements OnInit {
  @Input() item: any;
  @Input() id: any;

  title = {
    2: 'Calculadora Financeira',
    3: 'Como resolver minhas dívidas',
    4: 'Quanto trabalhar pra ganhar',
    5: 'Quem é o meu cliente',
    6: 'Calculadora da semana',
    7: 'Tempo de produção'
  };

  constructor(
  ) {
  }

  ngOnInit() {
  }

}
