import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { VimeoService } from 'src/app/@core/services/vimeo.service';
import { ChallengeMedia, ChallengeComponent } from 'src/app/@core/models/item-component';
import { SanitizerHelper } from 'src/app/@core/helpers/sanitizer.helper';

declare var $: any;

@Component({
  selector: 'app-editor-component-challenge-config',
  templateUrl: 'challenge-config.component.html',
  providers: [ VimeoService ]
})
export class EditorChallengeConfigComponent {
  @Input() item: ChallengeComponent;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  mediaToManipulate: ChallengeMedia;

  title = new FormControl();
  notesTitle = new FormControl();
  notesText = new FormControl();
  button = new FormControl();

  constructor(
    private sanitizer: DomSanitizer,
    private vimeoService: VimeoService
  ) {
  }

  setAlign(element: any, align: string) {
    element.align = align;
    this.emitSendEvent();
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  uploadSubmited($event) {
    if ($event.data) {
      this.mediaToManipulate.image = $event.data.url;
    } else {
      this.mediaToManipulate.image = $event.url;
    }
    this.mediaToManipulate = null;
    this.emitSendEvent();
  }

  getPhoto(media: ChallengeMedia, $event) {
    media.image = $event.path;
    this.emitSendEvent();
  }

  videoChanged(data: any) {
    const Sanitizer = new SanitizerHelper(this.sanitizer);
    if (data.type === 'youtube') {
      const videoID = Sanitizer.getYoutubeID(data.url);
      data.thumb = 'https://img.youtube.com/vi/' + Sanitizer.getYoutubeID(data.url) + '/hqdefault.jpg';
      data.link = Sanitizer.sanitizer(data.url, 'youtube');
    }
    if (data.type === 'vimeo') {
      data.thumb = 'https://i.vimeocdn.com/video/default_295x166.webp';
      this.vimeoService.getThumb(data.url).then(res => {
        data.thumb = res;
      });
      data.link = Sanitizer.sanitizer(data.url, 'vimeo');
    }

    this.emitSendEvent();

    $('iframe').attr('src', $('iframe').attr('src'));
  }

  insertMedia() {
    this.item.medias.push(new ChallengeMedia());
    this.emitSendEvent();
  }

  removeMedia(media: ChallengeMedia) {
    const index = this.item.medias.indexOf(media);
    this.item.medias.splice(index, 1);
  }

}
