import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SafePipe } from './safe.pipe';
import { YoutubeEmbedPipe } from './youtube-embed.pipe';
import { StreamPipe } from './stream.pipe';
import { DecodeURIPipe } from './decodeURI.pipe';
import { VimeoEmbedPipe } from './vimeo-embed.pipe';

@NgModule({
  declarations: [
    SafePipe,
    YoutubeEmbedPipe,
    VimeoEmbedPipe,
    StreamPipe,
    DecodeURIPipe
  ],
  exports: [
    SafePipe,
    YoutubeEmbedPipe,
    VimeoEmbedPipe,
    StreamPipe,
    DecodeURIPipe
  ],
  imports: [
    BrowserModule,
  ]
})

export class PipeModule { }
