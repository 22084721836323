import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrailBaseService } from 'src/app/@core/services/trail-base.service';
import { TrailStage } from 'src/app/@core/models/trail-stage';

@Component({
  selector: 'app-stage-dialog',
  templateUrl: 'stage-dialog.component.html',
})
export class StageDialogComponent {
  id: number;
  stage: TrailStage;

  isPosting: boolean;
  fileSelected: any;

  stageForm: FormGroup;

  constructor(
    public trailService: TrailBaseService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<StageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGenerate();

    this.id = +data.id;

    if (data.stage) {
      this.stage = data.stage;
      this.stageForm.patchValue({
        title: this.stage.title,
        sinopse: this.stage.sinopse,
        time: this.stage.time
      });
      this.fileSelected = this.stage.image;
    }
  }

  formGenerate() {
    this.stageForm = this.formBuilder.group({
      id: '',
      title: ['', Validators.required],
      sinopse: ['', Validators.required],
      time: ''
    });
  }

  onSubmit() {
    const form = this.stageForm;
    console.log(form.value);

    if (!form.valid) {
      return;
    }

    this.isPosting = true;

    const formValue = form.value;

    if (!this.stage) {
        this.insertStage(formValue.title, formValue.sinopse, formValue.time);
    } else {
        this.updateStage(formValue.title, formValue.sinopse, formValue.time);
    }

  }

  insertStage(title: string, sinopse: string, time: string) {
    this.trailService.insertStage(this.id, title, sinopse, time, this.fileSelected)
      .subscribe(res => {
        console.log('insertStage, res', res);

        const trailStage = new TrailStage(res.data.id, res.data.nome, res.data.image);
        trailStage.enable = false;
        trailStage.sinopse = res.data.sinopse;
        trailStage.time = res.data.time;
        trailStage.substages = [];

        this.closeDialog(trailStage);
      });
  }

  updateStage(title: string, sinopse: string, time: string) {
    this.trailService.updateStage(this.id, this.stage.id, title, sinopse, time, this.fileSelected)
      .subscribe(res => {
        console.log('updateStage, res', res);
        this.stage.title = title;
        this.stage.sinopse = sinopse;
        this.stage.time = time;
        this.stage.image = this.fileSelected;
        this.closeDialog(this.stage);
      });
  }

  closeDialog(trail: TrailStage): void {
    this.isPosting = false;
    this.stageForm.reset();
    this.dialogRef.close(trail);
  }

  getPhoto(image: {path: string}) {
    this.fileSelected = image.path;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
